<template>
  <div class="article">
    <div class="main-image">
      <img :src="cover" onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' " />
    </div>
    <div class="heading">
      <h1>{{ title }}</h1>
      <!-- <p>
        أخوكم حسن الهاجري يسلم عليكم ويقول لكم جهزوا أنفسكم لرحلة طويلة جدا
        وأحداث شيقة ومغامرات بوليسية. الدول التي سافرت لها خلال رحلتي هذه
        هي:كوبا، هندوراس، كوستاريكا، بنما، كولومبيا، الإكوادور، البيرو، وتشيلي.
      </p> -->
    </div>
    <div class="box" id="box" v-html="blogContent"></div>
    <div class="pagenation" dir="rtl">
      
    </div>
  </div>
</template>

<script>
export default {
  name: "my-article",
  data: function () {
    return {
      pagenationNumber: 10,
      
    };
  },
  props: ["title", "cover", "blogContent", "pagenationsNum"],
};
</script>

<style lang="scss" scoped>
.article {
  text-align: right;
  P {
    line-height: 1.9;
    color: #777f86;
    text-align: justify;
    direction: rtl;
  }
  .heading {
    h1 {
      font-size: 32px;
      font-weight: 700;
      color: #333;
      margin: 30px 0 15px 0;
    }
  }
  .main-image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .box {
    h2 {
      margin: 50px 0 15px 0;
      font-weight: 600;
    }
    p img {
      width: 100% !important;
      height: 100%;
      object-fit: cover;
    }
    .grid.three {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10px;
    }
    .grid.two {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
      row-gap: 10px;
    }
    .grid.one {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  .pagenation {
    margin-top: 40px;
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      font-weight: 600;
      color: #fff;
      li {
        width: 25px;
        height: 25px;
        background-color: rgba(51, 51, 51, 0.6);
        text-align: center;
        cursor: pointer;
        transition: background 0.4s ease-in-out;
        &:hover {
          background-color: #333;
        }
      }
      li.active {
        background-color: #333;
      }
    }
  }
}

.article #box p img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

@media (max-width: 500px) {
  .article .heading h1 {
    font-size: 26px;
  }
  .article p {
    font-size: 14px;
  }
  .article .pagenation ul {
    gap: 8px;
  }
  .article .box .grid.three {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
  .article .box .grid.two {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
  .article .box .grid.one {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
}
</style>
