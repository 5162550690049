<template>
  <div class="wraper">
    <div class="container">

      <div class="d-block text-right">

        <b-row align-h="start">
          <b-col v-if="article" @click="goBack" cols="1" style="cursor: pointer;">
            الرجوع
            <fa-icon style="vertical-align: -0.18em;" :icon="['fas', 'long-arrow-alt-right']" />
          </b-col>
        </b-row>
      </div>

      <b-alert variant="success" v-show="success" show>تمت الاضافة بنجاح</b-alert>
      <div class="add-post" dir="rtl">

        <h4> المدن المتعلقة بهذه الصفحة</h4>
        <!-- Add Cities as tags       -->
        <b-form-group  label-for="tags-component-select">
          <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
          <b-form-tags
              id="tags-component-select"
              v-model="page.cities"
              size="lg"
              class="mb-2"
              add-on-change
              no-outer-focus
          >
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
              <ul v-show="page.cities.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="c in page.cities" :key="c" class="list-inline-item">
                  <b-form-tag
                      @remove="removeTag(c)"
                      :title="c"
                      :disabled="disabled"
                      variant="primary"
                  >&nbsp; {{ ' ' + c + ' ' }} &nbsp; </b-form-tag>
                </li>
              </ul>
              <b-form-select
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  :disabled="disabled || availableOptions.length === 0"
                  :options="availableOptions"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">اختر من المدن الموجودة</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-form-group>

        <div id="editorcontainer">
          <h3 class="mt-4"> نص هذه الصفحة</h3>

          <b-row class="mt-4 mb-3">
            <b-col cols="10">
              <b-input
                  type="text"
                  size="sm"
                  v-model="mapCode"
              >
              </b-input>
            </b-col>
            <b-col cols="2">
              <b-button size="sm" @click="setEditorContent">أدخل الخريطة</b-button>
            </b-col>
          </b-row>
          <vue-editor
              ref="editor"
              scrollingContainer='html'
              id="editor"
              v-model="quillData"
              dir="rtl"
              :editorOptions="editorSettings"
              useCustomImageHandler
              @image-added="handleImageAdded"
              @focus="onEditorFocus"
          >
          </vue-editor>
        </div>

        <div class="add-button">
          <b-button class="mt-3" variant="primary" block @click.prevent="savePage">حفظ</b-button
          >
        </div>
      </div>
    </div>
    <loading v-show="loading" />
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

// Generate code-friendly font names
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

// Specify Quill fonts
const fontList = [ 'Arial', 'Cairo', 'Tajawal', 'El Messiri', 'Lemonada', 'Aref Ruqaa', 'Rakkas', 'Amiri', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);


// Import the BlockEmbed blot.
var BlockEmbed = Quill.import('blots/block/embed');

// Create a new format based off the BlockEmbed.
class Footer extends BlockEmbed {

  // Handle the creation of the new Footer format.
  // The value will be the HTML that is embedded.
  // This time the value is passed from our custom handler.
  static create(value) {

    // Create the node using the BlockEmbed's create method.
    var node = super.create(value);

    // Set the srcdoc attribute to equal the value which will be your html.
    node.setAttribute('srcdoc', value);

    // Add a few other iframe fixes.
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);
    node.setAttribute('width', '100%');

    console.log('hi there')

    return node;
  }
}


// Give our new Footer format a name to use in the toolbar.
  Footer.blotName = 'footer';

// Give it a class name to edit the css.
  Footer.className = 'ql-footer';

// Give it a tagName of iframe to tell quill what kind of element to create.
  Footer.tagName = 'iframe';

// Register the new Footer format so we can use it in our editor.
  Quill.register(Footer, true);

  // Create the footer handler.
  var footerHandler = function() {
    // Get the cursor location to know where footer will be added.
    var index = this.quill.getSelection(true).index;
    console.log('hi there')
    console.log(index)
  };

// Import the Toolbar module so we can add a custom handler to our footer button.
  var Toolbar = Quill.import('modules/toolbar');

// Add our custom footer handler to the footer button.
  Toolbar.DEFAULTS.handlers['footer'] = footerHandler;

// Quill.insertAdjacentHTML(`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53195.00881120534!2d35.34272535505486!3d33.56148046987761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ef03ff51e8597%3A0x181e41e3b9ff1086!2sSidon!5e0!3m2!1sen!2slb!4v1639373490896!5m2!1sen!2slb"  height="450" style="width:100%; border:0;" allowfullscreen="" loading="lazy"></iframe>`);



// var html = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53195.00881120534!2d35.34272535505486!3d33.56148046987761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ef03ff51e8597%3A0x181e41e3b9ff1086!2sSidon!5e0!3m2!1sen!2slb!4v1639373490896!5m2!1sen!2slb" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`;

// var delta = Quill.clipboard.convert(html);
// Quill.setContents(delta, 'silent');

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
      "content: '" + font + "';" +
      "font-family: '" + font + "', sans-serif;" +
      "}" +
      ".ql-font-" + fontName + "{" +
      " font-family: '" + font + "', sans-serif;" +
      "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

import loading from "../components/loading.vue";
import repository from "@/api/repository";


export default {
  name: "add-page",
  components: {
    VueEditor,
    loading,
  },
  data: function () {
    return {
      mapCode:null,
      blogTitle: "",
      quillData: "",
      mainImageChosen: "",
      file: "",
      page:{
        id:null,
        text:null,
        suggested_cities:[],
        cities:[],
      },
      quill_disabled:false,
      article:null,
      main_suggested_cities:[],
      fileName: "",
      country: "",
      loading: false,
      success: false,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
          toolbar: [
              // 'footer',
              // [{container: ['footer']}],
              [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" }
            ],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ["link", "image", "video"],
            ["clean"], // remove formatting button
            [{ 'font': fonts.whitelist }],
          ]

        },
        scrollingContainer: "body",
        theme: "snow"
      },
      options: ['نيو يورك', 'موسكو', 'الشارقة', 'الدمام', 'الخرطوم', 'البندقية', 'مدينة'],
      value: []
    };
  },
  methods: {
    setEditorContent() {

      var index = this.$refs.editor.quill.getSelection(true).index;
      var map = this.mapCode;

      if (map !== null && map.length > 0){
        this.$refs.editor.quill.disable();
        this.$refs.editor.quill.clipboard.dangerouslyPasteHTML(index, map, "api");
        this.$refs.editor.quill.enable();
      }

    },
    async savePage(){
      var new_cities = [];
      this.page.cities.forEach(new_city => {
        this.main_suggested_cities.forEach(e => {
          if (e.name == new_city)
            new_cities.push(e)
        })
      })

      const {data} = await repository.updatePage({
        id:this.$route.params.id,
        text:this.quillData,
        cities: new_cities
      });
      if (data){
        if (data.code == 200){
          // this.alertText = 'تمت الاضافة بنجاح';
          // this.alertType = 'success';
          // this.alertDisplay =true;
          window.location.reload(false);
        }
      } else {
        console.log(data)
      }

    },
    async getPage(){

      const {data} = await repository.getPage(this.$route.params.id);
      if (data){

        this.page.text = data.text;
        this.quillData  = data.text;
        this.article = data.article_id;
        this.main_suggested_cities = data.suggested_cities;
        this.page.suggested_cities = data.suggested_cities.map(x => { return x.name;})
        if (data.cities){
          var x = [];
          data.cities.forEach(c => {
            this.main_suggested_cities.forEach(m => {
              if (c.city_id == m.id){
                x.push(m.name);
              }
            })
          })
          this.page.cities = x;
        }

        document.getElementById('editor').style.maxHeight = '70vh'
        document.getElementById('editor').style.overflowY = 'scroll'

      } else {
        console.log(data)
      }

    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {

      console.log('started')
      var formData = new FormData();
      formData.append('image', file)

      const {data} = await repository.uploadImage(formData);
      if (data){
        let url = 'https://prod.sites-seen.com/current/public/images/'+data.image;
        Editor.insertEmbed(cursorLocation, 'image', url);
        resetUploader();
      } else {
        console.log(data)
      }
    },
    async goBack(){
      await this.$router.push({name:'edit-article', params: { id: this.article }}).catch(()=>{});
    },
    onEditorFocus(quill) {
      const regex = /(scroll)/;
      const style = (node, prop) => window.getComputedStyle(node, null).getPropertyValue(prop);
      const scroll = (node) => regex.test( style(node, "overflow") + style(node, "overflow-y") + style(node, "overflow-x"));
      const scrollparent = (node) => {
        return !node || node===document.body ? document.body : scroll(node) ? node : scrollparent(node.parentNode);
      };
      quill.scrollingContainer = scrollparent(quill.container);
    },
  },
  created() {
    this.$store.state.userVisibility = false;
    this.$nextTick(()=>{
      this.quill_disabled=false;
    });
    this.page.id = this.$route.params.id;
    this.getPage();
  },

  computed: {
    availableOptions() {
      return this.page.suggested_cities.filter(opt => this.value.indexOf(opt) === -1)
    }
  },
};
</script>

<style lang="scss" scoped>

html {
  height: 100%;
}


.ql-snow .ql-tooltip {
  position: fixed !important;
}
.wraper{
  font-family: var(--main-font);
}
.alert.alert-success{
  margin-top: 50px;
}
.add-post {
  font-family: var(--main-font);
  padding-bottom: 100px;
  padding-top: 60px;
  #quill-container {
    font-family: var(--main-font) !important;
  }
  .panel-card {
    margin: 46px 0 30px 0 !important;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h4 {
      text-align: right;
      margin: 25px 0;
      font-size: 24px;
      font-weight: 600;
    }
    input {
      height: 35px;
      width: 60%;
      border: none;
      border-bottom: 1px solid #333;
      padding: 25px 0px;
      background: none;
      outline: none;
      margin-bottom: 0px;
      font-size: 15px;
      color: rgb(59, 59, 59);
    }
    input:nth-child(2) {
      width: 20%;
    }
  }
  .add-button {
    text-align: right;
  }


}

//.quillWrapper{
//  height: 100% !important;
//}
//#editorcontainer{
//  height: 500px;
//  max-height: 500px !important;
//}
.ql-container .ql-snow {
  max-width: 999px;
  max-height: 500px !important;
  overflow-y: auto !important;
}

.ql-snow .ql-tooltip {
  position: fixed !important;
}

.ql-clipboard {
  position: fixed !important;
}


</style>
