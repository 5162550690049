<template>
  <div class="my-container">
    <div class="intro">
      <div class="container">
        <div class="info">
          <h1>{{title}}</h1>
          <p>
            <slot></slot>
          </p>
          <button class="main-btn"><a href="#">المزيد</a></button>
        </div>
      </div>
      <clock />
    </div>
  </div>
</template>

<script>
import clock from "../clock.vue"

export default {
  name: "introduction",
  components: {
    clock
  },
  props: ["title"]
};
</script>

<style lang="scss" scoped>
.intro {
  background: url("../../assets/bg/pexel.png") center;
  background-size: cover;
  height: 600px;
  font-family: var(--main-font);
  display: flex;
  align-items: center;
  text-align: right;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
  }
  .info {
    position: relative;
    z-index: 5;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    p {
      color: #e2e2e2;
      font-size: 18px;
      margin-block: 30px;
      margin-left: auto;
      width: 70%;
      line-height: 1.8;
    }
    button {
      font-weight: 600;
    }
  }
}

@media(max-width: 990px) {
  .intro .info{
    text-align: center;
    h1{
      font-size: 46px;
    }
    p{
      font-size: 15px;
      width: 100%
    }
  }
}
</style>
