<template>
  <div class="control-panel-table text-left">
    <div class="header ">
      <div class="holder d-flex align-items-center justify-content-between" dir="rtl">
        <h3 class="d-inline-block"> قم بإضافة بلدان - مدن - و مقالات : </h3>
        <manage-country-modal v-if="countries" :countries="countries" class=""/>
        <add-country-modal class=""/>
        <manage-city-modal v-if="countries" :countries="countries" class=""/>
        <add-city-modal v-if="countries" :countries="countries" class=""/>
        <add-article-modal v-if="countries" :countries="countries" class=""/>

      </div>
    </div>


    <table dir="rtl">
      <tr style="background-color: #d7d7e3">
        <th>الرقم</th>
        <th>العنوان</th>
        <th>اخر تعديل</th>
        <th>رابط الصورة</th>
        <th>البلد</th>
        <th>النشر</th>
        <th>وظائف</th>
      </tr>
      <tr v-for="(article, index) in articles" :key="index">
        <td>{{index + 1}}</td>
        <td>{{article.title}}</td>
        <td> <fa-icon :icon="['fas', 'clock']" /> {{ new Date(article.updated_at).toLocaleString() }}</td>
        <td>
          <b-img width="150"
                 onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
                 :src="'https://prod.sites-seen.com/current/public/thumbnails/' + article.photo"></b-img>
        </td>
        <td >{{ article.country? article.country.name : 'لا يوجد' }}</td>
        <td>
          <toggle-button :value="!!article.publish" @change="publishArticle(article.id)" color="#82C7EB" />
        </td>
        <td>
          <b-button class="action-btn" variant="warning" >
            <router-link class="underline" :to="{name: 'edit-article', params:{'id': article.id}}"> تعديل </router-link>
          </b-button>
          <b-button class="action-btn" variant="danger" v-b-modal.modal-delete
                    @click="selectedToDelete = article.id">حذف</b-button>

        </td>
      </tr>
    </table>

    <!--  Publish Toast  -->
    <b-toast  title="الحالة" ></b-toast>

    <!--  Delete Modal  -->
    <b-modal
        dir="rtl"
        size="lg"
        id="modal-delete"
        hide-footer
        title="هل تريد فعلا حذف هذا المقال؟">

      <div class="form-group d-flex justify-content-between footer">
        <b-button class="m-3" type="click" ref="show-delete-modal"
                  v-show="this.selectedToDelete"
                  @click.prevent="deleteArticle" variant="danger" >حذف</b-button>
        <b-button class="mt-3" variant="secondary"  @click="$bvModal.hide('modal-delete')">إغلاق</b-button>
      </div>

      <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
    </b-modal>

  </div>
</template>

<script>
import addArticleModal from "../dashboard/add-article-modal.vue";
import addCountryModal from "../dashboard/add-country-modal.vue";
import manageCountryModal from "../dashboard/manage-country-modal.vue";
import manageCityModal from "../dashboard/manage-city-modal.vue";
import addCityModal from "../dashboard/add-city-modal.vue";
import repository from "@/api/repository";

export default {
  name: 'control-panel-table',
  components: {
    addArticleModal,
    addCountryModal,
    manageCountryModal,
    manageCityModal,
    addCityModal,

  },
  props: ["articles"],
  data: function () {
    return {
      countries:null,
      selectionCountries:[],
      selectedToDelete:null,
      alertDisplay: false,
      alertType:'danger',
      alertText:'',

    }
  },
   methods :{
    async getCountries() {

       const {data} = await repository.getCountries();
       if (data && (data.length != 0) ){
         this.countries = data;

         this.countries.forEach((value) =>{
           this.selectionCountries.push({value:value.id, text:value.name + ' - ' + (new Date(value.visit_date)).getFullYear()})
         });
         this.$store.state.selectionCountries = this.selectionCountries;
       } else {
         console.log(data);
       }

     },
    async publishArticle(id){

      const {data} = await repository.publishArticle({'id':id});
      if (data && (data.length != 0) ){
        this.$bvToast.toast('تم تعديل حالة النشر بنجاح', {
          title: `الحالة`,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          autoHideDelay:3500,
          noHoverPause:true,
        })
      } else {
        this.$bvToast.toast('مشكلة في نشر المقالة', {
          title: `الحالة`,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
          autoHideDelay:3500,
          noHoverPause:true,
        })
        console.log(data);
      }

    },
    async deleteArticle () {
      const {data} = await repository.deleteArticle(this.selectedToDelete);
      if (data){
        if (data.code == 200){
          this.alertText = 'تم الحذف بنجاح';
          this.alertType = 'success';
          this.alertDisplay =true;
          window.location.reload(false);

        }
      } else {
        this.alertText = 'مشكلة أثناء الحذف';
        this.alertType = 'danger';
        this.alertDisplay =true;
      }
      setTimeout(this.$bvModal.hide('modal-delete'),2500);
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
    },

  },
  created () {
    this.getCountries()
  }
}
</script>

<style lang="scss" scoped>
.underline{
  text-decoration: none; color: white;
}
.control-panel-table{
  padding: 40px 40px;
  font-family: var(--main-font);
  .header{
    background-color: #fff;
    padding: 15px;
    h3{
      font-size: 20px;
      margin: 0;
      margin-right: 10px;
      span{
        color: #00b894;
        margin-left: 5px;
        font-size: 28px;
        font-weight: 600;
      }
    }
    input{
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      outline: none;
      font-size: 14px;
      font-weight: bold
    }
  }
  table{
    margin-top: 2rem  !important;
    background-color: #fff;
    width: 100%;
    tr{
      th{
        font-size: 1.2rem;
        text-align: center !important;
        color: #757575
      }
      td{
        text-align: center !important;
        font-size: 1rem;
        font-weight: bold;
        color: #868e96;
        padding-top: 15px;
        padding-bottom: 15px;
        svg{
          margin-right: 5px;
          font-size: 12px;
        }
        span, .action-btn{
          padding: 6px 10px;
          margin: 0 5px;
          color: #fff;
          cursor: pointer;
          font-size: .8rem;
          font-weight: bold;
          letter-spacing: 1px;
          border-radius: 4px;
        }
        span.delete{
          background-color: #ef5350;
        }
        span.edit{
          background-color: #e9ab2e ;
        }
        span.add{
          background-color: #00b894;
        }
        span.list{
          background-color: #0984e3;
        }
      }
    }
    tr:hover{
      background-color: rgba(0, 0, 0, .075)
    }
  }
}
</style>