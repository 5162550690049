<template>
  <div class="go-up" @click="goUp()">
    <button><fa-icon :icon="['fas', 'chevron-up']" /></button>
  </div>
</template>

<script>
export default {
  name: "go-up-button",
  methods: {
    goUp() {
      let current = document.documentElement.scrollTop;
      let frame = setInterval(iframe, 6)
      console.log(current)
      function iframe() {
        if (current < 0){
          clearInterval(frame)
        } else {
          current = current - 140;
          document.documentElement.scrollTop = current;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.go-up button{
  position: fixed;
  width: 45px;
  height: 45px;
  background-color: #f39c12;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  z-index: 1200;
  border: none;
  outline: none;
  svg{
    color: #fff
  }
}
</style>