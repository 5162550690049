<template>
  <div class="currency-convertor" dir="rtl">
    <div class="heading">
      <h4>تحويل العملات</h4>
    </div>
    <div class="currency" dir="rtl">
      <div class="box">
        <label for="amount">المبلغ</label>
        <input
            type="number"
            name="amount"
            value="1"
            id="valueOne"
            v-model="valueOne"
        />

        <label for="currencyFrom">من</label>

        <div
             name="currencyFrom"
        >

          <div class="display-value" id="displayValueFrom" @click="toggleOptions('from')">
            <img
                id="selectedFromImg"
                style="padding-left: 6px;"
                src="https://test.sites-seen.com/flags/us.png" width="30">

            <span class="value-text" id="selectedFromText">دولار أمريكي</span>
            <span class="arrow arrow-down" id="arrowControl1"></span>
          </div>
          <ul tabindex="0" class="select-container" id="selectContainerFrom" @blur="toggleOptions('from')">

            <li class="select-option"
                v-for="(currency, index) in currenciesNames"
                :key="index"
                :value="allCurrenciesInfo[currency]['currency_code']"
                @click="selected(allCurrenciesInfo[currency]['currency_code'], 'from')">
              <img
                  style="padding-left: 6px;"
                  :src="'https://test.sites-seen.com/flags/'+ allCurrenciesInfo[currency]['country_code'].toLowerCase() +'.png'" width="30">
              {{ allCurrenciesInfo[currency]["currency_ar_name"] }}
            </li>

          </ul>
        </div>


      </div>
      <div class="exchange">
        <fa-icon
            :icon="['fas', 'exchange-alt']"
            v-on:click="exchangeCurrency()"
        />
      </div>
      <div class="box">
        <label for="currencyTo">الى</label>
        <div
            name="currencyTo"
        >

          <div class="display-value" id="displayValueTo" @click="toggleOptions('to')">
            <img
                id="selectedToImg"
                style="padding-left: 6px;"
                src="https://test.sites-seen.com/flags/eu.png" width="30">

            <span class="value-text" id="selectedToText">يورو</span>
            <span class="arrow arrow-down" id="arrowControl"></span>
          </div>
          <ul tabindex="0" class="select-container" id="selectContainerTo" @blur="toggleOptions('to')">

            <li class="select-option"
                v-for="(currency, index) in currenciesNames"
                :key="index"
                :value="allCurrenciesInfo[currency]['currency_code']"
                @click="selected(allCurrenciesInfo[currency]['currency_code'], 'to')">
              <img
                  style="padding-left: 6px;"
                  :src="'https://test.sites-seen.com/flags/'+ allCurrenciesInfo[currency]['country_code'].toLowerCase() +'.png'" width="30">
              {{ allCurrenciesInfo[currency]["currency_ar_name"] }}
            </li>

          </ul>
        </div>

        <span>{{ valueTwo }}</span>
        <button @click="convertCurrency()">تحويل</button>
      </div>

    </div>

  </div>

</template>

<script>
import axios from "axios";


export default {
  name: "currency-convertor",
  data: function () {
    return {
      unsortedCurrenciesNames:['LRD', 'XCD', 'EUR', 'KMF', 'USD', 'THB', 'HUF', 'LBP', 'CRC', 'GYD', 'CDF', 'LYD', 'GMD', 'AMD', 'FJD', 'GNF', 'AWG', 'SCR', 'IRR', 'TJS', 'SHP', 'MNT', 'INR', 'ERN', 'GTQ', 'LSL', 'PEN', 'CHF', 'JPY', 'SDG', 'ZAR', 'TND', 'MDL', 'HNL', 'XAF', 'AUD', 'SZL', 'AED', 'SAR', 'MAD', 'AOA', 'IDR', 'NZD', 'CAD', 'VUV', 'BAM', 'TMT', 'YER', 'BDT', 'CUP', 'LAK', 'GBP', 'XPF', 'XOF', 'PYG', 'SBD', 'MYR', 'KPW', 'UYU', 'CLP', 'RWF', 'SOS', 'CNY', 'BBD', 'LKR', 'ALL', 'MUR', 'BHD', 'VES', 'RON', 'KWD', 'AZN', 'IQD', 'NPR', 'HTG', 'ZMW', 'MWK', 'BIF', 'PLN', 'KRW', 'KES', 'ETB', 'SEK', 'KYD', 'BYN', 'RUB', 'OMR', 'SYP', 'NGN', 'UZS', 'ARS', 'TZS', 'CZK', 'DJF', 'GIP', 'TRY', 'MRO', 'NAD', 'MKD', 'PGK', 'UAH', 'TOP', 'MXN', 'MOP', 'PAB', 'DKK', 'MZN', 'MMK', 'QAR', 'GEL', 'BGN', 'GHS', 'DOP', 'SRD', 'JOD', 'PKR', 'BTN', 'MVR', 'KZT', 'ISK', 'TWD', 'UGX', 'TTD', 'VND', 'PHP', 'BWP', 'STD', 'NOK', 'RSD', 'BSD', 'NIO', 'HKD', 'MGA', 'KGS', 'BND', 'COP', 'BRL', 'EGP', 'BZD', 'DZD', 'SLL', 'BOB', 'KHR', 'HRK', 'AFN', 'WST', 'JMD', 'SGD'],
      allCurrenciesInfo: {
        "LRD":{
          "currency_ar_name":"دولار ليبيري",
          "currency_code":"LRD",
          "country_code":"LR"
        },
        "XCD":{
          "currency_ar_name":"دولار شرق الكاريبي",
          "currency_code":"XCD",
          "country_code":"AG"
        },
        "EUR":{
          "currency_ar_name":"يورو",
          "currency_code":"EUR",
          "country_code":"EU"
        },
        "KMF":{
          "currency_ar_name":"فرنك جزر القمر",
          "currency_code":"KMF",
          "country_code":"KM"
        },
        "USD":{
          "currency_ar_name":"دولار أمريكي",
          "currency_code":"USD",
          "country_code":"US"
        },
        "THB":{
          "currency_ar_name":"باخت تايلاندي",
          "currency_code":"THB",
          "country_code":"TH"
        },
        "HUF":{
          "currency_ar_name":"فورينت مجري",
          "currency_code":"HUF",
          "country_code":"HU"
        },
        "LBP":{
          "currency_ar_name":"ليرة لبنانية",
          "currency_code":"LBP",
          "country_code":"LB"
        },
        "CRC":{
          "currency_ar_name":"كولن كوستا ريكي",
          "currency_code":"CRC",
          "country_code":"CR"
        },
        "GYD":{
          "currency_ar_name":"دولار غيانا",
          "currency_code":"GYD",
          "country_code":"GY"
        },
        "CDF":{
          "currency_ar_name":"فرنك كونغولي",
          "currency_code":"CDF",
          "country_code":"CD"
        },
        "LYD":{
          "currency_ar_name":"دينار ليبي",
          "currency_code":"LYD",
          "country_code":"LY"
        },
        "GMD":{
          "currency_ar_name":"دلاسي جامبي",
          "currency_code":"GMD",
          "country_code":"GM"
        },
        "AMD":{
          "currency_ar_name":"درام أرميني",
          "currency_code":"AMD",
          "country_code":"AM"
        },
        "FJD":{
          "currency_ar_name":"دولار فيجي",
          "currency_code":"FJD",
          "country_code":"FJ"
        },
        "GNF":{
          "currency_ar_name":"فرنك غينيا",
          "currency_code":"GNF",
          "country_code":"GN"
        },
        "AWG":{
          "currency_ar_name":"فلورن أروبي",
          "currency_code":"AWG",
          "country_code":"AW"
        },
        "SCR":{
          "currency_ar_name":"روبية سيشيلية",
          "currency_code":"SCR",
          "country_code":"SC"
        },
        "IRR":{
          "currency_ar_name":"ريال إيراني",
          "currency_code":"IRR",
          "country_code":"IR"
        },
        "TJS":{
          "currency_ar_name":"سوموني طاجيكستاني",
          "currency_code":"TJS",
          "country_code":"TJ"
        },
        "SHP":{
          "currency_ar_name":"جنيه سانت هيلين",
          "currency_code":"SHP",
          "country_code":"SH"
        },
        "MNT":{
          "currency_ar_name":"توغروغ منغولي",
          "currency_code":"MNT",
          "country_code":"MN"
        },
        "INR":{
          "currency_ar_name":"روبية هندي",
          "currency_code":"INR",
          "country_code":"IN"
        },
        "ERN":{
          "currency_ar_name":"ناكفا أريتري",
          "currency_code":"ERN",
          "country_code":"ER"
        },
        "GTQ":{
          "currency_ar_name":"كوتزال جواتيمالا",
          "currency_code":"GTQ",
          "country_code":"GT"
        },
        "LSL":{
          "currency_ar_name":"لوتي ليسوتو",
          "currency_code":"LSL",
          "country_code":"LS"
        },
        "PEN":{
          "currency_ar_name":"سول البيرو",
          "currency_code":"PEN",
          "country_code":"PE"
        },
        "CHF":{
          "currency_ar_name":"فرنك سويسري",
          "currency_code":"CHF",
          "country_code":"CH"
        },
        "JPY":{
          "currency_ar_name":"ين ياباني",
          "currency_code":"JPY",
          "country_code":"JP"
        },
        "SDG":{
          "currency_ar_name":"جنيه سوداني",
          "currency_code":"SDG",
          "country_code":"SD"
        },
        "ZAR":{
          "currency_ar_name":"راند جنوب أفريقيا",
          "currency_code":"ZAR",
          "country_code":"ZA"
        },
        "TND":{
          "currency_ar_name":"دينار تونسي",
          "currency_code":"TND",
          "country_code":"TN"
        },
        "MDL":{
          "currency_ar_name":"ليو مولدوفي",
          "currency_code":"MDL",
          "country_code":"MD"
        },
        "HNL":{
          "currency_ar_name":"ليمبيرا هنداروس",
          "currency_code":"HNL",
          "country_code":"HN"
        },
        "XAF":{
          "currency_ar_name":"فرنك وسط أفريقي",
          "currency_code":"XAF",
          "country_code":"TD"
        },
        "AUD":{
          "currency_ar_name":"دولار أسترالي",
          "currency_code":"AUD",
          "country_code":"TV"
        },
        "SZL":{
          "currency_ar_name":"ليلانجيني سوازيلندي",
          "currency_code":"SZL",
          "country_code":"SZ"
        },
        "AED":{
          "currency_ar_name":"درهم إماراتي",
          "currency_code":"AED",
          "country_code":"AE"
        },
        "SAR":{
          "currency_ar_name":"ريال سعودي",
          "currency_code":"SAR",
          "country_code":"SA"
        },
        "MAD":{
          "currency_ar_name":"درهم مغربي",
          "currency_code":"MAD",
          "country_code":"MA"
        },
        "AOA":{
          "currency_ar_name":"كوانزا أنجولي",
          "currency_code":"AOA",
          "country_code":"AO"
        },
        "IDR":{
          "currency_ar_name":"روبية إندونيسية",
          "currency_code":"IDR",
          "country_code":"ID"
        },
        "NZD":{
          "currency_ar_name":"دولار نيوزيلندي",
          "currency_code":"NZD",
          "country_code":"NZ"
        },
        "CAD":{
          "currency_ar_name":"دولار كندي",
          "currency_code":"CAD",
          "country_code":"CA"
        },
        "VUV":{
          "currency_ar_name":"فاتو فانواتو",
          "currency_code":"VUV",
          "country_code":"VU"
        },
        "BAM":{
          "currency_ar_name":"مارك البوسنة",
          "currency_code":"BAM",
          "country_code":"BA"
        },
        "TMT":{
          "currency_ar_name":"مانات تركمانستان",
          "currency_code":"TMT",
          "country_code":"TM"
        },
        "YER":{
          "currency_ar_name":"ريال يمني",
          "currency_code":"YER",
          "country_code":"YE"
        },
        "BDT":{
          "currency_ar_name":"تاكا بنجلاديشي",
          "currency_code":"BDT",
          "country_code":"BD"
        },
        "CUP":{
          "currency_ar_name":"بيزو كوبي",
          "currency_code":"CUP",
          "country_code":"CU"
        },
        "LAK":{
          "currency_ar_name":"كيب لاوسي",
          "currency_code":"LAK",
          "country_code":"LA"
        },
        "GBP":{
          "currency_ar_name":"جنيه إسترليني",
          "currency_code":"GBP",
          "country_code":"GB"
        },
        "XPF":{
          "currency_ar_name":"فرنك سي إف بي",
          "currency_code":"XPF",
          "country_code":"NC"
        },
        "XOF":{
          "currency_ar_name":"فرنك غرب أفريقي",
          "currency_code":"XOF",
          "country_code":"GW"
        },
        "PYG":{
          "currency_ar_name":"جواراني باراجواي",
          "currency_code":"PYG",
          "country_code":"PY"
        },
        "SBD":{
          "currency_ar_name":"دولار جزر سليمان",
          "currency_code":"SBD",
          "country_code":"SB"
        },
        "MYR":{
          "currency_ar_name":"رينغيت ماليزي",
          "currency_code":"MYR",
          "country_code":"MY"
        },
        "KPW":{
          "currency_ar_name":"وون كوريا الشمالية",
          "currency_code":"KPW",
          "country_code":"KP"
        },
        "UYU":{
          "currency_ar_name":"بيزو اوروغواي",
          "currency_code":"UYU",
          "country_code":"UY"
        },
        "CLP":{
          "currency_ar_name":"بيزو شيلي",
          "currency_code":"CLP",
          "country_code":"CL"
        },
        "RWF":{
          "currency_ar_name":"فرنك رواندي",
          "currency_code":"RWF",
          "country_code":"RW"
        },
        "SOS":{
          "currency_ar_name":"شلن صومالي",
          "currency_code":"SOS",
          "country_code":"SO"
        },
        "CNY":{
          "currency_ar_name":"يوان صيني",
          "currency_code":"CNY",
          "country_code":"CN"
        },
        "BBD":{
          "currency_ar_name":"دولار بربادوسي",
          "currency_code":"BBD",
          "country_code":"BB"
        },
        "LKR":{
          "currency_ar_name":"روبية سريلانكية",
          "currency_code":"LKR",
          "country_code":"LK"
        },
        "ALL":{
          "currency_ar_name":"ليك ألباني",
          "currency_code":"ALL",
          "country_code":"AL"
        },
        "MUR":{
          "currency_ar_name":"روبية موريشيوسية",
          "currency_code":"MUR",
          "country_code":"MU"
        },
        "BHD":{
          "currency_ar_name":"دينار بحريني",
          "currency_code":"BHD",
          "country_code":"BH"
        },
        "VES":{
          "currency_ar_name":"بوليفار فينزويلي",
          "currency_code":"VES",
          "country_code":"VE"
        },
        "RON":{
          "currency_ar_name":"ليو روماني",
          "currency_code":"RON",
          "country_code":"RO"
        },
        "KWD":{
          "currency_ar_name":"دينار كويتي",
          "currency_code":"KWD",
          "country_code":"KW"
        },
        "AZN":{
          "currency_ar_name":"مانات أذربيجان",
          "currency_code":"AZN",
          "country_code":"AZ"
        },
        "IQD":{
          "currency_ar_name":"دينار عراقي",
          "currency_code":"IQD",
          "country_code":"IQ"
        },
        "NPR":{
          "currency_ar_name":"روبية نيبالي",
          "currency_code":"NPR",
          "country_code":"NP"
        },
        "HTG":{
          "currency_ar_name":"جوردى هايتي",
          "currency_code":"HTG",
          "country_code":"HT"
        },
        "ZMW":{
          "currency_ar_name":"كواشا زامبي",
          "currency_code":"ZMW",
          "country_code":"ZM"
        },
        "MWK":{
          "currency_ar_name":"كواشا مالاوي",
          "currency_code":"MWK",
          "country_code":"MW"
        },
        "BIF":{
          "currency_ar_name":"فرنك بروندي",
          "currency_code":"BIF",
          "country_code":"BI"
        },
        "PLN":{
          "currency_ar_name":"زلوتي بولندي",
          "currency_code":"PLN",
          "country_code":"PL"
        },
        "KRW":{
          "currency_ar_name":"وون كوريا الجنوبية",
          "currency_code":"KRW",
          "country_code":"KR"
        },
        "KES":{
          "currency_ar_name":"شلن كينيي",
          "currency_code":"KES",
          "country_code":"KE"
        },
        "ETB":{
          "currency_ar_name":"بير أثيوبي",
          "currency_code":"ETB",
          "country_code":"ET"
        },
        "SEK":{
          "currency_ar_name":"كرونة سويدية",
          "currency_code":"SEK",
          "country_code":"SE"
        },
        "KYD":{
          "currency_ar_name":"دولار جزر كيمن",
          "currency_code":"KYD",
          "country_code":"KY"
        },
        "BYN":{
          "currency_ar_name":"روبل بيلاروسي",
          "currency_code":"BYN",
          "country_code":"BY"
        },
        "RUB":{
          "currency_ar_name":"روبل روسي",
          "currency_code":"RUB",
          "country_code":"RU"
        },
        "OMR":{
          "currency_ar_name":"ريال عماني",
          "currency_code":"OMR",
          "country_code":"OM"
        },
        "SYP":{
          "currency_ar_name":"ليرة سورية",
          "currency_code":"SYP",
          "country_code":"SY"
        },
        "NGN":{
          "currency_ar_name":"نايرا نيجيري",
          "currency_code":"NGN",
          "country_code":"NG"
        },
        "UZS":{
          "currency_ar_name":"سوم أوزبكستاني",
          "currency_code":"UZS",
          "country_code":"UZ"
        },
        "ARS":{
          "currency_ar_name":"بيزو أرجنتيني",
          "currency_code":"ARS",
          "country_code":"AR"
        },
        "TZS":{
          "currency_ar_name":"شلن تنزاني",
          "currency_code":"TZS",
          "country_code":"TZ"
        },
        "CZK":{
          "currency_ar_name":"كرونة تشيكية",
          "currency_code":"CZK",
          "country_code":"CZ"
        },
        "DJF":{
          "currency_ar_name":"فرنك جيبوتي",
          "currency_code":"DJF",
          "country_code":"DJ"
        },
        "GIP":{
          "currency_ar_name":"جنيه جبل طارق",
          "currency_code":"GIP",
          "country_code":"GI"
        },
        "TRY":{
          "currency_ar_name":"ليرة تركية",
          "currency_code":"TRY",
          "country_code":"TR"
        },
        "MRO":{
          "currency_ar_name":"أوقية موريتانية",
          "currency_code":"MRO",
          "country_code":"MR"
        },
        "NAD":{
          "currency_ar_name":"دولار ناميبي",
          "currency_code":"NAD",
          "country_code":"NA"
        },
        "MKD":{
          "currency_ar_name":"دينار مقدوني",
          "currency_code":"MKD",
          "country_code":"MK"
        },
        "PGK":{
          "currency_ar_name":"كينا بابوا غينيا الجديدة",
          "currency_code":"PGK",
          "country_code":"PG"
        },
        "UAH":{
          "currency_ar_name":"هريفنيا أوكراني",
          "currency_code":"UAH",
          "country_code":"UA"
        },
        "TOP":{
          "currency_ar_name":"بانغا تونغا",
          "currency_code":"TOP",
          "country_code":"TO"
        },
        "MXN":{
          "currency_ar_name":"بيزو مكسيكي",
          "currency_code":"MXN",
          "country_code":"MX"
        },
        "MOP":{
          "currency_ar_name":"باتاكا ماكاوي",
          "currency_code":"MOP",
          "country_code":"MO"
        },
        "PAB":{
          "currency_ar_name":"بالبوا بنمي",
          "currency_code":"PAB",
          "country_code":"PA"
        },
        "DKK":{
          "currency_ar_name":"كرونة دانماركي",
          "currency_code":"DKK",
          "country_code":"DK"
        },
        "MZN":{
          "currency_ar_name":"متكال موزمبيقي",
          "currency_code":"MZN",
          "country_code":"MZ"
        },
        "MMK":{
          "currency_ar_name":"كيات ميانمار",
          "currency_code":"MMK",
          "country_code":"MM"
        },
        "QAR":{
          "currency_ar_name":"ريال قطري",
          "currency_code":"QAR",
          "country_code":"QA"
        },
        "GEL":{
          "currency_ar_name":"لارى جورجي",
          "currency_code":"GEL",
          "country_code":"GE"
        },
        "BGN":{
          "currency_ar_name":"ليف بلغاري",
          "currency_code":"BGN",
          "country_code":"BG"
        },
        "GHS":{
          "currency_ar_name":"سيدي غانا",
          "currency_code":"GHS",
          "country_code":"GH"
        },
        "DOP":{
          "currency_ar_name":"بيزو الدومنيكان",
          "currency_code":"DOP",
          "country_code":"DO"
        },
        "SRD":{
          "currency_ar_name":"دولار سورينامي",
          "currency_code":"SRD",
          "country_code":"SR"
        },
        "JOD":{
          "currency_ar_name":"دينار أردني",
          "currency_code":"JOD",
          "country_code":"JO"
        },
        "PKR":{
          "currency_ar_name":"روبية باكستاني",
          "currency_code":"PKR",
          "country_code":"PK"
        },
        "BTN":{
          "currency_ar_name":"نولتوم بوتاني",
          "currency_code":"BTN",
          "country_code":"BT"
        },
        "MVR":{
          "currency_ar_name":"روفيه جزر المالديف",
          "currency_code":"MVR",
          "country_code":"MV"
        },
        "KZT":{
          "currency_ar_name":"تينغ كازاخستاني",
          "currency_code":"KZT",
          "country_code":"KZ"
        },
        "ISK":{
          "currency_ar_name":"كرونه أيسلندي",
          "currency_code":"ISK",
          "country_code":"IS"
        },
        "TWD":{
          "currency_ar_name":"دولار تايواني",
          "currency_code":"TWD",
          "country_code":"TW"
        },
        "UGX":{
          "currency_ar_name":"شلن أوغندي",
          "currency_code":"UGX",
          "country_code":"UG"
        },
        "TTD":{
          "currency_ar_name":"دولار ترينداد وتوباجو",
          "currency_code":"TTD",
          "country_code":"TT"
        },
        "VND":{
          "currency_ar_name":"دونج فيتنامي",
          "currency_code":"VND",
          "country_code":"VN"
        },
        "PHP":{
          "currency_ar_name":"بيزو فلبيني",
          "currency_code":"PHP",
          "country_code":"PH"
        },
        "BWP":{
          "currency_ar_name":"بولا بتسواني",
          "currency_code":"BWP",
          "country_code":"BW"
        },
        "STD":{
          "currency_ar_name":"دوبرا ساو تومي",
          "currency_code":"STD",
          "country_code":"ST"
        },
        "NOK":{
          "currency_ar_name":"كرونة نرويجية",
          "currency_code":"NOK",
          "country_code":"NO"
        },
        "RSD":{
          "currency_ar_name":"دينار صربي",
          "currency_code":"RSD",
          "country_code":"RS"
        },
        "BSD":{
          "currency_ar_name":"دولار باهامي",
          "currency_code":"BSD",
          "country_code":"BS"
        },
        "NIO":{
          "currency_ar_name":"قرطبة نيكاراغوا",
          "currency_code":"NIO",
          "country_code":"NI"
        },
        "HKD":{
          "currency_ar_name":"دولار هونغ كونغ",
          "currency_code":"HKD",
          "country_code":"HK"
        },
        "MGA":{
          "currency_ar_name":"أرياري مدغشقر",
          "currency_code":"MGA",
          "country_code":"MG"
        },
        "KGS":{
          "currency_ar_name":"سوم قيرغستاني",
          "currency_code":"KGS",
          "country_code":"KG"
        },
        "BND":{
          "currency_ar_name":"دولار بروناي",
          "currency_code":"BND",
          "country_code":"BN"
        },
        "COP":{
          "currency_ar_name":"بيزو كولومبي",
          "currency_code":"COP",
          "country_code":"CO"
        },
        "BRL":{
          "currency_ar_name":"ريال برازيلي",
          "currency_code":"BRL",
          "country_code":"BR"
        },
        "EGP":{
          "currency_ar_name":"جنيه مصري",
          "currency_code":"EGP",
          "country_code":"EG"
        },
        "BZD":{
          "currency_ar_name":"دولار بليزي",
          "currency_code":"BZD",
          "country_code":"BZ"
        },
        "DZD":{
          "currency_ar_name":"دينار جزائري",
          "currency_code":"DZD",
          "country_code":"DZ"
        },
        "SLL":{
          "currency_ar_name":"ليون سيراليوني",
          "currency_code":"SLL",
          "country_code":"SL"
        },
        "BOB":{
          "currency_ar_name":"بوليفيانو بوليفي",
          "currency_code":"BOB",
          "country_code":"BO"
        },
        "KHR":{
          "currency_ar_name":"رييال كمبودي",
          "currency_code":"KHR",
          "country_code":"KH"
        },
        "HRK":{
          "currency_ar_name":"كونا كرواتي",
          "currency_code":"HRK",
          "country_code":"HR"
        },
        "AFN":{
          "currency_ar_name":"أفغاني",
          "currency_code":"AFN",
          "country_code":"AF"
        },
        "WST":{
          "currency_ar_name":"تالا ساموا",
          "currency_code":"WST",
          "country_code":"WS"
        },
        "JMD":{
          "currency_ar_name":"دولار جامايكي",
          "currency_code":"JMD",
          "country_code":"JM"
        },
        "SGD":{
          "currency_ar_name":"دولار سنغافوري",
          "currency_code":"SGD",
          "country_code":"SG"
        }
      },
      currenciesNames:[],
      allCurrunciesValues:{},
      currencyFrom: "USD",
      currencyTo: "EUR",
      valueOne: "1",
      valueTwo: "",
      isOpenFrom: false,
      isOpenTo: false,
    };
  },
  methods: {
    async convertCurrency() {
      await axios
          .get(
              `https://api.exchangerate.host/latest?base=${this.currencyFrom}`
          )
          .then((result) => result.data)
          .then((data) => {
            if(data.success == true){
              this.allCurrunciesValues = data.rates;
              let converted =
                  data.rates[this.currencyTo] * this.valueOne;
              this.valueTwo = converted;
            } else {
              console.log(data)
            }

          });
    },
    exchangeCurrency() {

      document.getElementById('selectedFromImg').src = 'https://test.sites-seen.com/flags/'+ this.allCurrenciesInfo[this.currencyTo]['country_code'].toLowerCase() +'.png';
      document.getElementById('selectedFromText').innerText = this.allCurrenciesInfo[this.currencyTo]['currency_ar_name'];

      document.getElementById('selectedToImg').src = 'https://test.sites-seen.com/flags/'+ this.allCurrenciesInfo[this.currencyFrom]['country_code'].toLowerCase() +'.png';
      document.getElementById('selectedToText').innerText = this.allCurrenciesInfo[this.currencyFrom]['currency_ar_name'];

      const temp = this.currencyFrom;
      this.currencyFrom = this.currencyTo;
      this.currencyTo = temp;
      this.convertCurrency();
    },
    toggleOptions(type) {

      if (type == 'from'){
        this.isOpenFrom = !this.isOpenFrom;
        if (this.isOpenFrom) {
          document.getElementById('selectContainerFrom').style.visibility = 'visible';
          document.getElementById('selectContainerFrom').focus();
        } else {
          document.getElementById('selectContainerFrom').blur();
          document.getElementById('selectContainerFrom').style.visibility = 'hidden';
        }
      } else {
        this.isOpenTo = !this.isOpenTo;
        if (this.isOpenTo) {
          document.getElementById('selectContainerTo').style.visibility = 'visible';
          document.getElementById('selectContainerTo').focus();
        } else {
          document.getElementById('selectContainerTo').blur();
          document.getElementById('selectContainerTo').style.visibility = 'hidden';
        }
      }


    },
    selected(currency_code, type) {
      if (type == 'from'){
        document.getElementById('selectedFromImg').src = 'https://test.sites-seen.comm/flags/'+ this.allCurrenciesInfo[currency_code]['country_code'].toLowerCase() +'.png';
        document.getElementById('selectedFromText').innerText = this.allCurrenciesInfo[currency_code]['currency_ar_name'];
        this.currencyFrom = currency_code;
        this.toggleOptions('from');

        // this.changeCurrency();
      } else {
        document.getElementById('selectedToImg').src = 'https://test.sites-seen.com/flags/'+ this.allCurrenciesInfo[currency_code]['country_code'].toLowerCase() +'.png';
        document.getElementById('selectedToText').innerText = this.allCurrenciesInfo[currency_code]['currency_ar_name'];
        this.currencyTo = currency_code;
        this.toggleOptions('to');
        // this.changeCurrency();
      }
    },

  },
  created() {
    this.currenciesNames = this.unsortedCurrenciesNames.sort();
    this.convertCurrency();
  },
};
</script>

<style lang="scss" scoped>

*, *:after, *:before {
  box-sizing: border-box;
}
.display-value {
  text-align: start;
  box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 98%;
  outline: none;
  padding: 0 10px;
  background-color: #fff;
  color: #333 !important;
  font-weight: 600;
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 20px;
  span{
    color: #333 !important;
    font-weight: 600;
    font-size: 16px !important;
    margin-top: 5px;
    display: inline-block;
  }

}
.value-text {
  margin-left: 0 !important;
  display: flex;
  padding-left: 10px;
  align-items: center;
  color: #666;
}
.arrow {
  float: left;
  //left: -90px;
  top: 7px;
  position: relative;
}
.arrow.arrow-up {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid black;
}
.arrow.arrow-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
}
.select-container {
  width: 240px;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  margin: 0px;
  height: 40vh;
  z-index: 20;
  max-height: 1000px;
  font-family: 'Cairo', sans-serif;
  overflow: scroll;
  border-radius: 7px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  list-style-type: none;
  display: block;
}
.select-container:focus {
  outline:none;
}
.select-option {
  display: none;
  height: 40px;
  display: flex;
  padding-left: 10px;
  font-family: 'Cairo', sans-serif;
  align-items: center;
  color: #666;
}
.select-option:hover {
  background-color: #eee;
}

.currency-convertor {
  padding: 30px 0 10px 0;
  border: 3px solid rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  margin: 35px auto !important;
  box-shadow: 0px 0px 25px 1px rgb(50 50 50 / 7%);
  width: 90%;
  .heading {
    h4 {
      font-family: var(--main-font);
      color: #333;
    }
  }
  .currency {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    .exchange {
      width: 100px;
      width: 100px;
      transform: rotate(90deg) translateY(10px);
      svg {
        cursor: pointer;
        font-size: 16px;
        font-weight: normal;
        color: rgb(192, 192, 192);
      }
    }
    .box {
      display: grid;
      column-gap: 5px;
      grid-template-columns: 1fr;
      font-family: var(--main-font);
      width: 93%;
      margin: 0 auto;
      label {
        text-align: right;
        margin-bottom: 7px;
        font-size: 13px;
        font-weight: 700;
      }
      input {
        height: 40px;
        outline: none;
        width: 100%;
        padding: 0 20px;
        font-weight: 600;
        margin-bottom: 20px;
        border-radius: 7px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;
        color: #333;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
      }
      select {
        height: 40px;
        outline: none;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;
        color: #333;
        font-weight: 600;
        cursor: pointer;
        border-radius: 7px;
        margin-bottom: 20px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
        option {
          padding: 10px 0;
          margin: 5px 0;
        }
      }
      span {
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        color: #4cd137;
        margin-left: 5px;
      }
      button {
        border: 0;
        outline: 0;
        background-color: #4cd137;
        color: #fff;
        font-weight: 700;
        height: 40px;
        border-radius: 7px;
        margin: 20px 0;
      }
    }
  }
}

@media (max-width: 520px) {
  .currency-convertor {
    margin: 35px auto !important;
  }
  .currency-convertor .box {
    padding-right: 0 !important;
  }
}

@media (max-width: 990px) {
  .currency-convertor {
    margin: 35px auto !important;
  }
}
</style>
