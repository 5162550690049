<template>
  <div dir="rtl">
    <b-button id="show-btn" @click="showModal" variant="dark" >
      للاستفسار و الشكاوى
    </b-button>

    <b-modal
        id="footer-modal"
        dir="rtl"
        size="xl"
        ref="add-complain-modal"
        hide-footer
        hide-header-close
        hide-header
    >
      <form ref="form" @submit.stop.prevent="addComplain">

        <h5 class="mb-5 text-center" >أكتب استفسارك أو شكواك يسعدنا تواصلك معنا دائما</h5>

        <div class="d-block text-right">
          <div class="form-group">
            <label for="exampleInputEmail1">الاسم</label>
            <input type="text" class="form-control" id="exampleInputEmail1"
                   v-model="contact.name" aria-describedby="" placeholder="" required>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail2">البريد الالكتروني</label>
            <input type="email" class="form-control" id="exampleInputEmail2"
                   v-model="contact.email" aria-describedby="" placeholder="" required>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">النص المختصر</label>
            <b-form-textarea
                id="textarea-large"
                size="lg"
                no-resize
                v-model="contact.text"
                placeholder="قم بكتابة الشكوى أو الاستفسار"
            ></b-form-textarea>
            <!--              :state="(article.body >= 10) && (article.body < 400)"-->

          </div>


        </div>

        <div class="form-group d-flex justify-content-between footer">
          <b-button class="m-3" type="submit" variant="primary" >إضافة</b-button>
          <b-button class="m-3" variant="secondary"  @click="hideModal()">إغلاق</b-button>
        </div>
      </form>


      <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
    </b-modal>
  </div>
</template>

<script>
import repository from "@/api/repository";

export default {
  name: "contact-modal",
  data: function (){
    return{
      contact: {
        name:null,
        email:null,
        text:null
      },
      alertText:'',
      alertType:'',
      alertDisplay:null,
    }
  },
  methods:{
    showModal() {
      this.$refs['add-complain-modal'].show()
    },
    hideModal() {
      this.$refs['add-complain-modal'].hide()
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
    },
    async addComplain() {

      const {data} = await repository.addComplain(this.contact);
      if (data){
        if (data.id){
          this.alertText = 'تمت الاضافة بنجاح';
          this.alertType = 'success';
          this.alertDisplay =true;

        }
        if (data.code == -1){
          this.alertText = data.msg;
          this.alertType = 'danger';
          this.alertDisplay =true;
        }
      } else {
        this.alertText = 'مشكلة أثناء الاضافة';
        this.alertType = 'danger';
        this.alertDisplay =true;
      }

      setTimeout(this.hideModal,3000);

    }

  }
}
</script>

<style scoped>
#footer-modal .modal-header{
  align-items: center !important;
  justify-content: center !important;
}


.title-cls{
  text-align: start !important;
}

#footer-modal___BV_modal_title_ {
  text-align: start !important;
}
</style>