<template>
  <div class="blog" dir="rtl">
    <div class="blog-img">
      <img
          style="min-height: 15rem; height: 38rem; max-height: 50vmax;"
          :src="'https://prod.sites-seen.com/current/public/images/'+imageLink"
          onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
          alt="">
    </div>
    <div class="blog-info">
      <h4><router-link to="#"> {{ title }} </router-link></h4>
      <div class="blog-status d-flex">
        <fa-icon :icon="['fas', 'map-marker-alt']" />
        <span> {{ country }}</span> <small>|</small>
        <fa-icon :icon="['fas', 'clock']" />
        <span>{{ formatTime(date) }}</span>
      </div>
      <p >
        <slot>{{ content }}</slot>
      </p>
      <button class="main-btn"><router-link to="#">قراءة المزيد</router-link></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "blog",
  props: {
    title: {default: "Title Is Here"},
    country: {default: "Country"},
    date: {default: "August 4, 2021"},
    imageLink: {default: "1635237577.jpg"},
    contet: {default: "<h1>ahmed</h1>"},
  },
  methods:{
    formatTime(timestamp) {
      return (new Date(timestamp)).toLocaleDateString('ar-EG', { month: 'long', day: 'numeric', year: 'numeric' })
    }
  }
}
</script>

<style lang="scss" scoped>
.blog{
  text-align: right;
  font-family: var(--main-font);
  height: 100%;
  width: auto;
  min-height: 25rem;
  .blog-img{
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-size: .8rem;
    img{
      width: 100%;
      //height: 100%;
      object-fit: cover;
      transition: all 0.4s ease-in-out;
    }
  }
  .blog-info{
    h4{
      margin: 25px 0;
      a{
        color: var(--main-color);
        text-decoration: none;
        font-weight: 600;
        transition: color 0.4s ease-in-out;
        font-size: 2rem;
        &:hover{
          color: var(--third-color);
        }
      }
    }
    .blog-status{
      align-items: center;
      font-size: 1.2rem;
      svg{
        color: var(--main-color)
      }
      small{
        margin-inline: 10px;
      }
      span{
        margin-right: 10px;
        font-weight: bold;
        color: var(--third-color);
      }
    }
    p{
      color: var(--main-color);
      margin: 20px 0;
      line-height: 1.9;
      font-family: #5f5f5f;
      font-size: 1.2rem;
    }
    button {
      margin-top: 10px;
      background-color: var(--main-color);
      transition: all 0.5s ease;
      a{
        transition: all 0.4s ease-in-out;
        color: #fff
      }
      &:hover{
        background-color: rgba(0, 0, 0, 0.7)
      }
    }
  }
  &:hover .blog-img img{
    transform: scale(1.1) rotate(-2deg);
    filter: grayscale(50%)
  }
}

@media(max-width: 990px){
  .blog .blog-info h4 a{
    font-size: 25px
  }
  .blog .blog-info p{
    color: #444444;
    font-size: 15px;
  }
}

@media(max-width: 500px) {
  .blog .blog-info p{
    text-align: justify;
    font-size: 14px;
  }
  .blog .blog-img{
    border-radius: 0px;
  }
  button.main-btn{
    width: 140px;
    height: 45px;
  }
  .blog .blog-info button a{
    font-size: 16px;
  }
}
</style>