<template>
  <div class="admin">
    <div class="container">
      <h1>sites-seen.com</h1>
      <div class="holder" dir="rtl">
        <b-form >
          <b-form-group id="input-group-1" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="user.email"
                type="email"
                placeholder="البريد الالكتروني"
                required
            ></b-form-input>

            <b-form-input
                id="input-2"
                v-model="user.password"
                type="password"
                placeholder="كلمة السر"
                required
            ></b-form-input>
          </b-form-group>
          <b-button @click.prevent="login" variant="primary">تسجيل</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.admin {
  background-color: rgb(247, 247, 247);
  height: 100vh;
  display: flex;
  align-items: center;
  font-family: var(--main-font);
  h4{
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
    span{
      color: #ffd111;
      font-weight: normal;
    }
  }
  .holder {
    background-color: #fff;
    padding: 50px 40px;
    width: 60%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 0px 25px 1px rgb(50 50 50 / 7%);
  }
  .form-control{
    margin-bottom: 30px;
    height: 40px;
  }
}

@media(max-width: 500px){
  .admin .holder{
    width: 90%
  }
}
</style>

<script>

export default {
  name:"SignIn",
  data: () => ({
    user:{
      email:null,
      password:''
    },
    error:null,
    loading: false,

  }),
  created() {
    this.$store.state.userVisibility = false;

  },

  methods: {

      async login(){
        this.error = null;

        try {
          await this.$store.dispatch('login', this.user);
          await this.$router.push({name:'Home'});
        } catch (error){
          this.error = error;
        } finally {
          this.loading = false;
        }

      }

    },

  computed: {

  },

}
</script>
