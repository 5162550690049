<template>
  <div class="gallery-image-holder">
    <div class="image-holder" @click="closeImageHolder()">
      <img :src="imageSrc" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "gallery-image-holder",
  props: {
    imageSrc: {default: "a"}
  },
  methods: {
    closeImageHolder () {
      this.$emit('whenImageHolderClosed')
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-image-holder{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  .image-holder{
    text-align: center !important;
    padding: 100px 0;
    width: 90%;
    margin: 0 auto;
    padding: 100px;
  }
  img {
    min-height: 100%;
    //min-width: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 500px) {
  .gallery-image-holder .image-holder{
    padding: 0;
  }
}
</style>