<template>
  <div class="my-container">
    <footer>
      <div class="container">
        <div class="row align-items-center">
          <div style="align-self: center" class="logo col-lg-4 col-sm-12">
            <img src="../assets/logo-white.png"
                 @click.prevent="goHome"
                 onerror="this.style.display='none'; this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
                 alt="logo">
          </div>
          <div align-self="center" class="signeture col-lg-4 col-sm-12">
            <p>جميع الحقوق محفوظة</p>
          </div>
          <div class="links col-lg-4 col-sm-12 order-sm-1 mt-sm-5 mt-md-0">
            <contact-modal></contact-modal>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import contact from "../components/contact-modal";
import ContactModal from "@/components/contact-modal";

export default {
  name:"app-footer",
  components: {ContactModal},
  comments:{
    contact,
  },
  data: function (){
    return{

    }
  },
  methods:{
    async goHome(){
      await this.$router.push({name:'Home'}).catch(()=>{});
    },
  }
}
</script>

<style scoped>

.modal-header{
  align-items: center !important;
  justify-content: center !important;
}
.modal-title{
  text-align: center !important;
}
#footer-modal___BV_modal_title_ {
  text-align: center !important;
}
h5{
  text-align: center !important;
}

footer{
  background-color: var(--main-color);
  color: #fff;
  font-family: var(--main-font)
}

footer .logo{
  /*text-align: left;*/
}

footer .logo img{
  width: 120px;
  height: 100%;
}

footer .links{
  /*text-align: right;*/
}

footer .links a{
    color: #fff;
    text-decoration: none;
}

/* footer .links ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

footer .links ul li{
  width: 50px;
  height: 50px;
  line-height: 57px;
  border-radius: 50%;
  transition: transform 0.5s ease-in-out
}

footer .links ul li:first-child{
  background-color: #1877F2
}
footer .links ul li:nth-child(2){
  background-color: #E4405F
}
footer .links ul li:nth-child(3){
  background-color: #1DA1F2
}
footer .links ul li:nth-child(4){
  background-color: #CD201F
}

footer .links ul li:hover{
  transform: rotate(360deg)
}

footer .links ul li a{
  color: #fff;
  display: block;
  
}

footer .links ul li a svg{
  font-size: 25px;
} */

footer .signeture p{
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

@media(max-width: 500px){
  footer .row div {
    width: auto !important;
  }
  footer .logo img {width: 110px !important;}
  footer .signeture {margin-bottom: 20px;}
  footer .signeture p{font-size: 15px}
  footer .links {margin-bottom: 25px;}
  footer .links a{font-size: 15px}
  footer .row{
    justify-content: center;
    flex-direction: column;
  }
}
</style>