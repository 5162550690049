<template>
  <div class="clock">
    <p><span id="clock">{{koko}}</span> <fa-icon :icon="['fas', 'clock']" /></p>
  </div>
</template>

<script>
export default {
  name: "clock",
  data: function (){
    return {
      dayNow: "",
      yearNow: "",
      monthNow: "",
      minuteNow: "",
      secondNow: "",
      dateFormat: "",
      koko: ""
    }
  },
  methods: {
    getRalTime() {
      const dayNumberToString = {
        1: "الاثنين",
        2: "الثلاثاء",
        3: "الاربعاء",
        4: "الخميس",
        5: "الجمعة",
        6: "السبت",
        7: "الاحد",
        0: "الاحد",
      };

      const monthNumberToString = {
        1: 'يناير',
        2: 'فبراير',
        3: 'مارس',
        4: 'ابريل',
        5: 'مايو',
        6: 'يونيو',
        7: 'يوليو',
        8: 'اغسطس',
        9: 'سبتمبر',
        10: 'اكتوبر',
        11: 'نوفمبر',
        12: 'ديسمبر',
      }

      
      setInterval(function () {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const monthDay = currentDate.getUTCDate();
        const day = currentDate.getDay();
        const year = currentDate.getFullYear();
        const dayInArabic = dayNumberToString[day];
        const monthInArabic = monthNumberToString[month];


        const hours = currentDate.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        const minute = currentDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        const seconds = currentDate.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

        let hoursFormat = hours;
        let e = 'ص'
        if (hoursFormat > 12){
          e = 'م'
          hoursFormat = hoursFormat - 12;
          if (hoursFormat.toString().length === 1){
            hoursFormat = `0${hoursFormat}`
          }
        } else {
          hoursFormat = `${hoursFormat}`;
          e = 'ص'
        }
        let clockElement = document.getElementById("clock");
        clockElement.innerHTML = `${dayInArabic} ${monthDay} ${monthInArabic} ${year}    الساعة الان  ${hoursFormat}:${minute}:${seconds}  ${e}`
      }, 1000)

    }
  },
  created () {
    
  },
  mounted () {
    this.getRalTime();
  }
}
</script>

<style lang="scss" scoped>
.clock{
    bottom: 0px;
    left: 0%;
    // transform: translateX(-50%);
    z-index: 66;
    padding: 10px 25px;
    font-family: var(--main-font);
    font-size: 16px;
    background-color: #1d1d1d;
    width: fit-content;
    position: absolute;
  p{
    display: flex;
    align-items: center;
    margin: 0;
    color: #fff
  }
  svg{
    margin-left: 10px;
    color: var(--third-color);
    font-size: 18px
  }
}
</style>