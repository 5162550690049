<template>
  <div class="blog" dir="rtl">
    <div class="blog-img">
      <img
          style="min-height: 15rem; height: 30rem; max-height: 50vmax;"
          :src="'https://prod.sites-seen.com/current/public/small_images/'+imageLink"
          onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
          alt="">
    </div>
    <div class="blog-info">
      <h4 @click.prevent="goToArticle" >  {{ title }}  </h4>
      <div class="blog-status d-flex">
        <fa-icon :icon="['fas', 'map-marker-alt']" />
        <span> {{ country }}</span> <small>|</small>
        <fa-icon :icon="['fas', 'clock']" />
        <span>{{ formatTime(date) }}</span>
      </div>
      <p class="content">
        <slot>{{ content }}</slot>
      </p>
      <button @click.prevent="goToArticle" class="main-btn">قراءة المزيد</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "blog",
  props: {
    title: {default: "Title Is Here"},
    country: {default: "Country"},
    date:{},
    imageLink: {default: "1635237577.jpg"},
    contet: {default: "<h1>ahmed</h1>"},
    id:{},
  },
  methods:{
    async goToArticle(){
      if(this.id != null){
        await this.$router.push({name:'article', params: { id: this.id, page: 1 }});
      }
    },
    formatTime(timestamp) {
      return (new Date(timestamp)).toLocaleDateString('ar-EG', { month: 'long', day: 'numeric', year: 'numeric' })

    }
  }
}
</script>

<style lang="scss" scoped>

.main-btn{
  color: white ;
}

.content{
  height: 6.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog{
  text-align: right;
  font-family: var(--main-font);
  height: 100%;
  width: auto;
  min-height: 25rem;
  .blog-img{
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-size: .8rem;
    img{
      width: 100%;
      //height: 100%;
      object-fit: cover;
      transition: all 0.4s ease-in-out;
    }
  }
  .blog-info{
    h4{
      margin: 25px 0;
      color: var(--main-color);
      text-decoration: none;
      font-weight: 600;
      transition: color 0.4s ease-in-out;
      font-size: 1.5rem;
      cursor: pointer;

      &:hover{
        color: var(--third-color);
      }

    }
    .blog-status{
      align-items: center;
      font-size: 1.2rem;
      svg{
        color: var(--main-color)
      }
      small{
        margin-inline: 10px;
      }
      span{
        margin-right: 10px;
        font-weight: bold;
        color: var(--third-color);
      }
    }
    p{
      color: var(--main-color);
      margin: 20px 0;
      line-height: 1.9;

      font-size: 1.2rem;
    }
    button {
      margin-top: 10px;
      background-color: var(--main-color);
      transition: all 0.5s ease;
      a{
        transition: all 0.4s ease-in-out;
        color: #fff
      }
      &:hover{
        background-color: rgba(0, 0, 0, 0.7)
      }
    }
  }
  &:hover .blog-img img{
    transform: scale(1.1) rotate(-2deg);
    filter: grayscale(50%)
  }
}

@media(max-width: 990px){
  .blog .blog-info h4 a{
    font-size: 25px
  }
  .blog .blog-info p{
    color: #444444;
    font-size: 15px;
  }
}

@media(max-width: 500px) {
  .blog .blog-info p{
    text-align: justify;
    font-size: 14px;
  }
  .blog .blog-img{
    border-radius: 0px;
  }
  button.main-btn{
    width: 140px;
    height: 45px;
  }
  .blog .blog-info button a{
    font-size: 16px;
  }
}
</style>