<template>
  <div class="wraper">
    <div class="container" v-if="article" dir="rtl">

      <form ref="form" >

        <div class="d-block text-right">

          <b-row align-h="end">
            <b-col @click="goBack" cols="1" style="cursor: pointer;">
              الرجوع
              <fa-icon style="vertical-align: -0.18em;" :icon="['fas', 'long-arrow-alt-right']" />
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col cols="11"  align-self="end">
              <h2> تعديل البيانات الرئيسية </h2>
            </b-col>
          </b-row>

          <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>

          <div class="form-group">
            <label for="exampleInputEmail13">عنوان المقالة</label>
            <input type="text" class="form-control" id="exampleInputEmail13"
                   v-model="article.title" aria-describedby="" placeholder="" required>
          </div>

          <div class="form-group">
            <label for="textarea-large">النص المختصر</label>
            <b-form-textarea
                id="textarea-large"
                size="lg"
                no-resize
                v-model="article.body"
                aria-invalid="false"
                placeholder="قم بإدخال شرح مختصر (10-400 محرف)"
            ></b-form-textarea>
<!--            :state="(article.body >= 10) && (article.body < 400)"-->

          </div>

          <div class="form-group">
            <label >اختر الدولة</label>
            <b-form-select v-model="country"
                           @change="getCities"
                           :options="selectionCountries" size="sm" class="mt-3" required></b-form-select>

          </div>

          <div class="form-group">
            <label >اختر المدينة</label>
            <b-form-select v-model="city"
                           :options="selectionCities" size="sm" class="mt-3" required></b-form-select>

          </div>

          <div class="form-group">
            <b-row>
              <b-col cols="8">
                <label >صورة المقالة</label>
                <b-form-file  id="article_image" @change="handleImageAdded" ></b-form-file>
              </b-col>
              <b-col cols="4">
                <b-img width="150" height="150" :src="'https://prod.sites-seen.com/current/public/thumbnails/' + article.photo"></b-img>
              </b-col>
            </b-row>

          </div>

          <div class="form-group d-flex justify-content-between footer">
            <b-button class="m-3" @click.prevent="editArticle" type="button" variant="primary" block >تعديل</b-button>
          </div>

        </div>


      </form>

      <b-card header="الصفحات المضافة لهذه المقالة">

        <b-list-group v-if="article.pages && article.pages.length !=0 ">

          <b-list-group-item v-for="(page, i) in article.pages" :key="page.id" class="text-right">
            {{ 'الصفحة ' + (i+1) +': ' }}
            <span v-html=" page.text != null ? page.text : 'لا يوجد نص في هذه الصفحة'"></span>
            <b-button pill size="sm" style="float: left"
                      class="mx-2"
                      @click.prevent="selectedToDelete = page.id"
                      v-b-modal.modal-1
                      variant="danger">حذف</b-button>
            <b-button pill size="sm" style="float: left"
                      v-b-modal.modal-1
                      variant="warning" @click.prevent="goToEditPage(page.id)">تعديل</b-button>

          </b-list-group-item>

          <b-modal hide-footer id="modal-1" title="تنبيه">
            <p class="my-4">هل أنت متأكد من عملية الحذف؟
              سيتم حذف هذه الصفحة!</p>
            <b-button class="mt-3" variant="outline-danger" block
                      @click.prevent="deletePage">تأكيد الحذف</b-button>
            <b-button class="mt-2" variant="outline-secondary" block @click="hideModal">إلغاء العملية</b-button>
          </b-modal>

        </b-list-group>

        <b-list-group v-else>
          <b-list-group-item href="#" class="text-center">
            لا يوجد أية صفحات مضافة
          </b-list-group-item>
        </b-list-group>

        <b-button class="my-4" @click.prevent="addPage" type="button" variant="dark"  block>إضافة صفحة جديدة</b-button>

      </b-card>

    </div>
  </div>
</template>

<script>
import { Quill } from "vue2-editor";


import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
import repository from "@/api/repository";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "editArticle",
  components: {

  },
  data: function () {
    return {
      article:null,
      country: null,
      countries:[],
      city: null,
      cities:[],
      selectionCountries:[],
      selectionCities:[],
      alertDisplay: false,
      alertType:'danger',
      alertText:'',
      selectedToDelete:null,
      article_photo:null,
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getArticle(to.params.id));
  },
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    this.article = null;
    this.getArticle(to.params.id);
    next();
  },

  methods: {

    async getCountries(){
      //Get countries first
      const {data} = await repository.getCountries();
      if (data && (data.length != 0) ){
        this.countries = data;

        this.countries.forEach((value) =>{
          this.selectionCountries.push({value:value.id, text:value.name + ' - ' + (new Date(value.visit_date)).getFullYear()})
        });
        this.$store.state.selectionCountries = this.selectionCountries;
      } else {
        console.log(data);
      }
    },
    async getArticle(id){

      this.getCountries();
      //Get Article later
      const art = await repository.getArticle(id);
      if (art.data){
        this.article = art.data;

        this.selectionCountries.unshift({value:0 , text: 'غير مرتبطة بدول'});
        // this.selectionCountries.push.apply(this.selectionCountries, this.$store.state.selectionCountries);

        this.selectionCities = [{value:0 , text: 'غير مرتبطة بمدن'}];
        this.article.cities.forEach((value) =>{
          this.selectionCities.push({value:value.id, text:value.name})
        })

        this.country = this.article.country_id;
        this.city = this.article.city_id;


      } else {
        console.log(art);
      }
    },
    async editArticle(){

      const {data} = await repository.updateArticle({
        'city_id':this.city,
        'country_id':this.country,
        'title':this.article.title,
        'body':this.article.body,
        'id':this.article.id,
        'photo':this.article.photo
      });
      if (data && (data.id) ){
        this.alertText = 'تم التعديل بنجاح';
        this.alertType = 'success';
        this.alertDisplay =true;
        window.location.reload(false);

      } else {
        this.alertText = 'مشكلة أثناء التعديل';
        this.alertType = 'danger';
        this.alertDisplay =true;
      }
    },
    async addPage(){
      const {data} = await repository.addPage({
        'id':this.article.id
      });
      if (data && (data.code==200) ){
        this.alertText = 'تمت الإضافة بنجاح';
        this.alertType = 'success';
        this.alertDisplay =true;
        window.location.reload(false);
      } else {
        this.alertText = 'مشكلة أثناء الإضافة';
        this.alertType = 'danger';
        this.alertDisplay =true;
      }

      setTimeout(this.hideModal,3000);
    },
    async deletePage(){
      const {data} = await repository.deletePage(this.selectedToDelete);
      if (data && (data.code==200) ){
        this.alertText = 'تم الحذف بنجاح';
        this.alertType = 'success';
        this.alertDisplay =true;
        window.location.reload(false);

      } else {
        this.alertText = 'مشكلة أثناء الحذف';
        this.alertType = 'danger';
        this.alertDisplay =true;
      }

      setTimeout(this.hideModal,3000);
    },
    async getCities(){
      const {data} = await repository.getCities(this.country);
      if (data){
        if (data.length !=0 ){
          this.cities = data;
          this.selectionCities = [{value:0 , text: 'غير مرتبطة بمدن'}];
          this.cities.forEach((value) =>{
            this.selectionCities.push({value:value.id, text:value.name})
          })
        } else {
          this.selectionCities = [{value:0, text:"لا يوجد مدن مضافة"}];
        }

      } else {
        this.cities = null;
      }
    },
    async goToEditPage(id){
      await this.$router.push({name:'edit-page', params: { id: id }});
    },
    async goBack(){
      await this.$router.push({name:'admin-panel'});
    },
    async handleImageAdded(event) {

      var formData = new FormData();
      formData.append('image', event.target.files[0])

      const {data} = await repository.uploadImage(formData);
      if (data){
        this.article.photo = data.image;
      } else {
        console.log(data)
      }
    },

    showModal() {
      this.$refs['add-post-modal'].show()
    },
    hideModal() {
      this.$refs['add-post-modal'].hide()
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
    },
  },
  watch:{
    country: {
      handler: function(val) {
        if (val == 0 ){
          this.selectionCities = [{value:0 , text: 'غير مرتبطة بمدن'}];
          this.city = 0;
        }
      },
      immediate: true
    },

  },
  computed: {

  },
};
</script>

<style lang="scss" scoped>
.wraper {
  font-family: var(--main-font);
}
.alert.alert-success {
  margin-top: 50px;
}
.add-post {
  font-family: var(--main-font);
  padding-bottom: 100px;
  padding-top: 60px;
  #quill-container {
    font-family: var(--main-font) !important;
  }
  .panel-card {
    margin: 46px 0 30px 0 !important;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h4 {
      text-align: right;
      margin: 25px 0;
      font-size: 24px;
      font-weight: 600;
    }
    input {
      height: 35px;
      width: 60%;
      border: none;
      border-bottom: 1px solid #333;
      padding: 25px 0px;
      background: none;
      outline: none;
      margin-bottom: 0px;
      font-size: 15px;
      color: rgb(59, 59, 59);
    }
    input:nth-child(2) {
      width: 20%;
    }
  }
  .add-button {
    text-align: right;
  }
  .add-button button {
    margin-left: 15px;
    a{
      color: #fff;
      text-decoration: none
    }
  }
}
</style>
