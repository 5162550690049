<template>
  <div class="control-panel-card d-flex justify-content-between align-items-center p-3"
       @click.prevent="goToPage"
       :style="bgColor">
    <div class="icon" :style="icnColor">
      <fa-icon :icon="icn" />
    </div>
    <div class="info text-right" :style="color">
      <span class="d-block">{{ num }}</span>
      <span>{{type}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "control-panel-card",
  data: function () {
    return {

    }
  },
  methods:{
    goToPage(){
      if (this.goTo != null)
      this.$router.push({name:this.goTo});
    },
  },
  props: {
    num: {default: 100},
    type: {default: "Types"},
    icn: {default: ['fas', 'heart']},
    bgColor: {default: "background-color: #fff"},
    color: {default: "color: #2c3e50"},
    icnColor: {default: "color: #2c3e50"},
    goTo: {default: null}
  }
}
</script>

<style lang="scss" scoped>
.control-panel-card{
  background-color: #745af2;
  cursor: pointer;
  height: 100px;
  color: #fff;
  svg{
    font-size: 36px;
  }
  span:first-child{
    font-size: 38px;
    line-height: 1.2;
    
    letter-spacing: 3px;
  }
  span{
    font-size: 14px;
  }
}
</style>