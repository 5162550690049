import Vue from "vue";
import Vuex from "vuex";
import repository from "../api/repository";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    URL:'https://prod.sites-seen.com/current/public/api/',
    userVisibility:false,
    randomArticles:[],
    galleryImages: [
      "newzeland1.png",
      "japan1.jpg",
      "NewZealand2.png",
      "Peru1.png",
      "pexel.png",
      "pexel2.jpg",
      "Russia1.png",
      "Russia2.png",
      "Mexico1.png",
    ],
    selectionCountries: [],
    blogPosts: [
      {
        id: 1,
        title: "مقال عن رحلة اليابان",
        country: "اليابان",
        date: "5 اغسطس 2020",
        text: "خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو, إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ انه, ربع الأثنان المقيتة في, اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام, الصين وتنامت حين ٣٠, ونتج والحزب المذابح كل جوي. أسر كارثة المشتّتون بل, وبعض وبداية الصفحة غزو قد, أي بحث تعداد الجنوب. القاذفات عل مما. ببعض مئات وبلجيكا، قد أما, قِبل الدنمارك حتى كل, العمليات اليابانية انه أن.",
        imageName: "Japan2.png",
      },
      {
        id: 2,
        title: "مقال عن رحلة اليابان",
        country: "اليابان",
        date: "5 اغسطس 2020",
        text: "خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو, إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ انه, ربع الأثنان المقيتة في, اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام, الصين وتنامت حين ٣٠, ونتج والحزب المذابح كل جوي. أسر كارثة المشتّتون بل, وبعض وبداية الصفحة غزو قد, أي بحث تعداد الجنوب. القاذفات عل مما. ببعض مئات وبلجيكا، قد أما, قِبل الدنمارك حتى كل, العمليات اليابانية انه أن.",
        imageName: "Russia1.png",
      },
      {
        id: 3,
        title: "مقال عن رحلة اليابان",
        country: "اليابان",
        date: "5 اغسطس 2020",
        text: "خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو, إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ انه, ربع الأثنان المقيتة في, اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام, الصين وتنامت حين ٣٠, ونتج والحزب المذابح كل جوي. أسر كارثة المشتّتون بل, وبعض وبداية الصفحة غزو قد, أي بحث تعداد الجنوب. القاذفات عل مما. ببعض مئات وبلجيكا، قد أما, قِبل الدنمارك حتى كل, العمليات اليابانية انه أن.",
        imageName: "Russia2.png",
      },
      {
        id: 4,
        title: "مقال عن رحلة اليابان",
        country: "اليابان",
        date: "5 اغسطس 2020",
        text: "خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو, إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ انه, ربع الأثنان المقيتة في, اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام, الصين وتنامت حين ٣٠, ونتج والحزب المذابح كل جوي. أسر كارثة المشتّتون بل, وبعض وبداية الصفحة غزو قد, أي بحث تعداد الجنوب. القاذفات عل مما. ببعض مئات وبلجيكا، قد أما, قِبل الدنمارك حتى كل, العمليات اليابانية انه أن.",
        imageName: "Mexico1.png",
      },
    ],
    updatedBlog: [],
    blogPostsForHome: []
  },
  mutations: {
    // pushNewCountry (state, value) {
    //   state.countries.push(value)
    // },
    // pushUpdatedblog (state, value) {
    //   state.updatedBlog.unshift(value)
    // },
    // pushBlogsForHome (state, value) {
    //   state.blogPostsForHome.push(value)
    // }
  },
  actions: {},
  modules: {
    auth:{
      state: () => ({
        user:sessionStorage.user ? JSON.parse(sessionStorage.getItem('user')) : null
      }),
      getters:{
        user: state => state.user,
        authenticated: state => state.user !== null,
      },
      mutations:{
        SET_USER(state, user){
          state.user = user;
        }
      },
      actions:{
        async login({commit}, user){

          // await repository.createSession();
          const {data} = await repository.login(user);
          commit('SET_USER', data);
          sessionStorage.user = JSON.stringify(data)

        },
        async signup({commit}, user){

          await repository.createSession();
          const {data} = await repository.signUp(user);
          commit('SET_USER', data);
          sessionStorage.user = JSON.stringify(data)

        },
        async logout({commit}){

          await repository.logout();
          commit('SET_USER', null);
          sessionStorage.removeItem('user') ;

        }
      }
    }
    },
});
