<template>
  <div class="gallery">
    <div class="heading">
      <h2>صور الرحلات</h2>
      <p>تصويرى من الراحلات والمناطق اتى قمت بزيارتها</p>
    </div>
    <div class="container-fluid px-2">
      <div class="holder">
        <div
          class="box"
          v-for="(imageName, index) in getGalleryImages"
          :key="index"
          @click="openImage($event)"
        >
          <img :src="require(`../../assets/bg/${imageName}`)" />
        </div>
      </div>
    </div>
    <galleryImageHolder v-if="imageHolder" :imageSrc="clickedImage" @whenImageHolderClosed="closeImage()"/>
  </div>
</template>

<script>
import galleryImageHolder from "../gallery-image-holder.vue";

export default {
  name: "gallery",
  components: {
    galleryImageHolder,
  },
  data: function () {
    return {
      imageHolder: false,
      clickedImage: ""
    };
  },
  methods: {
    openImage (event) {
      this.imageHolder = true
      this.clickedImage = event.target.src
      console.log(this.clickedImage)
    },
    closeImage() {
      this.imageHolder = false
    },
  },
  computed: {
    getGalleryImages() {
      return this.$store.state.galleryImages;
    },
    // imageHold() {
    //   return this.$store.state.galleryImages;
    // },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.box{
  font-size: .8rem;
}
.gallery {
  max-width: 1520px;
  margin: 0 auto;
  background-color: #fcfcfc;
  padding: 3rem 0;
  .heading {

    font-family: var(--main-font);
    h2 {
      font-size: 36px;
      color: var(--main-color);
      position: relative;
      font-weight: 600;
      margin-bottom: 40px;
      &::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 3px;
        background-color: var(--third-color);
        bottom: -20px;
        left: calc(50% - 20px);
      }
    }
    p {
      font-size: 18px;
      color: rgb(54, 54, 54);
      margin-bottom: 30px;
    }
  }
  .holder {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 300px);
    column-gap: 20px;
    row-gap: 20px;
    .box {
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .box:first-child {
      grid-column: span 2;
    }
    .box:nth-child(5) {
      grid-row: span 2;
    }
    .box:nth-child(6) {
      grid-column: span 2;
    }
  }
}

@media (max-width: 990px) {
  .holder {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
  }
}
@media (max-width: 500px) {
  .holder {
    display: block !important;
    .box{
      margin: 30px 0;
    }
  }
}
</style>
