<template>
  <div class="my-container">
    <div class="container">
    <div class="my-container">
      <div class="home-blog-posts">
        <div class="section-holder">
          <div class="holder">
            <post
                v-for="article in articles"
                :id="article.id"
                :key="article.id"
                :title="article.title"
                :country="article.country_id == 0 ? 'مقالة عامة' : article.country.name"
                :date="article.country_id == 0 ? article.created_at: article.country.visit_date"
                :imageLink="article.photo"
            >
              {{ article.body }}
            </post>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import post from "../blog.vue";
import repository from "@/api/repository";

export default {
  name: "main-posts",
  components: {
    post,
  },
  data: function () {
    return {
      articles: [],
    };
  },
  methods: {
    async getFourArticles(){
      const {data} = await repository.getFourArticles();
      if (data){
        this.articles = data;
      } else {
        console.log(data);
      }
    },
  },
  created() {
    this.getFourArticles();
  }
};
</script>

<style lang="scss" scoped>

.container{
  padding-top: 5rem ;
  padding-bottom: 5rem ;

}

.home-blog-posts {

  font-family: var(--main-font);
  .heading {
    h2 {
      font-size: 36px;
      color: var(--main-color);
      position: relative;
      font-weight: 600;
      margin-bottom: 40px;
      &::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 3px;
        background-color: var(--third-color);
        bottom: -20px;
        left: calc(50% - 20px);
      }
    }
    p {
      font-size: 18px;
      color: rgb(54, 54, 54);
      margin-bottom: 50px;
    }
  }
  .holder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 50px;
  }
  .section-holder {
    padding: 0 0px;
  }
}
@media (max-width: 990px) {
  .home-blog-posts .holder {
    grid-template-columns: 1fr;
  }
  .section-holder{
    grid-template-columns: 1fr !important;
  }

}

@media (max-width: 500px){
  .container{
    padding-top: 1.5rem;
  }
  .home-blog-posts .section-holder{
    padding: 0 15px;
    margin-top: 35px;
  }
  .home-blog-posts .section-holder .side-bar{
    grid-template-columns: 1fr;
    padding: 0;
    .side-img{
      order: 2;
      height: auto !important;
    }
    .home-blog-posts .section-holder .side-bar .side-posts{
      order: 1 !important;
    }
  }
  .home-blog-posts .section-holder .side-bar .side-posts{
      order: 2;
      display: grid !important;
      grid-template-columns: 1fr 1fr;
  }
  .home-blog-posts .section-holder .side-bar .side-posts .side-post .post-text h3{
    font-size: 10px;
  }
  .home-blog-posts{
    padding: 30px 0 !important;
  }
}

@media (min-width: 1400px){
  .container {
    min-width: 98%;
  }
}
</style>
