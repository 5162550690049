<template>
  <div>
    <div
        class="content"
        v-html="data"
        @click="openImage($event)"
    >
    </div>
    <galleryImageHolder v-if="imageHolder" :imageSrc="clickedImage" @whenImageHolderClosed="closeImage()"/>

  </div>
</template>

<script>

import galleryImageHolder from "./gallery-image-holder";


export default {
  name: "page-content",
  props: ["data"],
  components: {
    galleryImageHolder,
  },
  data:function (){
    return{
      imageHolder: false,
      clickedImage: "",
    }
  },
  methods:{
    openImage (event) {
      if ((event.target.tagName === 'IMG') || (event.target.tagName === 'img')) {
        this.imageHolder = true
        this.clickedImage = event.target.src
        console.log(this.clickedImage)      }

    },
    closeImage() {
      this.imageHolder = false
    },
  }
}
</script>

<style lang="scss" scoped>
img{
  max-width: 1000px !important;
}


.ql-align-right > img{
  max-width: 90% !important;
  max-height: 100% !important;
  width: 90% !important;
  height: auto !important;
}

.content{
  align-self: center !important;
  max-width: 1030px;
  //width: 65%;
  clear: both !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.content img{
  max-width: 50vw;
}

.ql-editor .ql-align-right {
  text-align: right !important;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em !important;
}
h1 .ql-align-right {
  float: right !important;
  text-align: right !important;
}
@media (max-width: 1000px) {
  img{
    width: 70vw !important;
  }
}

</style>