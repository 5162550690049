<template>
  <div class="soso">
    <div>
      <b-button id="show-btn" @click="showModal" variant="secondary">
        المدن المسجلة
      </b-button>
      <b-modal
          dir="rtl"
          size="xl"
          ref="add-post-modal"
          hide-header-close
          hide-footer
          title="قم بإدارة اسماء المدن">

        <div class="form-group text-right" style="margin-top: 2rem;">


            <div class="d-block text-right" v-if="selectionCountries">
              <div class="form-group">
                <label for="countryselect">اختر الدولة</label>
                <b-form-select v-model="selectedCountry"
                               @change="getCities"
                               :options="selectionCountries" size="sm" class="mt-3" id="countryselect"></b-form-select>

              </div>
            </div>

          <b-card header="المدن المضافة لهذه الدولة">

            <b-list-group v-if="cities">

              <b-list-group-item v-for="city in cities" :key="city.id" href="#"> {{ city.name }}
                <b-button pill size="sm" style="float: left"
                          @click.prevent="selectedToDelete = city.id"
                          v-b-modal.modal-1
                          variant="danger">حذف</b-button>
              </b-list-group-item>

              <b-modal hide-footer id="modal-1" title="تنبيه">
                <p class="my-4">هل أنت متأكد من عملية الحذف؟
                  سيتم حذف هذه المدينة!</p>
                <b-button class="mt-3" variant="outline-danger" block
                          @click.prevent="deleteCity">تأكيد الحذف</b-button>
                <b-button class="mt-2" variant="outline-secondary" block @click="hideModal">إلغاء العملية</b-button>
              </b-modal>

            </b-list-group>

            <b-list-group v-else>
              <b-list-group-item href="#" class="text-center">
                لا يوجد أية مدن مضافة لهذه الدولة
              </b-list-group-item>
            </b-list-group>


          </b-card>

          <div class="form-group d-flex justify-content-between ">
            <b-button class="mt-3" variant="secondary"  @click="hideModal()">إغلاق</b-button>
          </div>


        </div>


        <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
      </b-modal>
    </div>
  </div>
</template>

<script>
import repository from "../../api/repository";

export default {
  name: "manageCityModal",
  props:["countries"],
  data: function () {
    return{
      selectedToDelete:null,
      selectedCountry:null,
      selectionCountries:[],
      cities:null,
      alertDisplay: false,
      alertType:'danger',
      alertText:''
    }
  },
  methods: {
    showModal() {
      this.$refs['add-post-modal'].show()
    },
    hideModal() {
      this.$refs['add-post-modal'].hide()
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
    },
    async deleteCity() {

      const {data} = await repository.deleteCity(this.selectedToDelete);
      if (data && (data.code==200) ){
        this.alertText = 'تم الحذف بنجاح';
        this.alertType = 'success';
        this.alertDisplay =true;
      } else {
        this.alertText = 'مشكلة أثناء الحذف';
        this.alertType = 'danger';
        this.alertDisplay =true;
      }

      setTimeout(this.hideModal,3000);

    },
    async getCities() {

      const {data} = await repository.getCities(this.selectedCountry);
      if (data && (data.length !=0 ) ){
        this.cities = data;
      } else {
        this.cities = null;
      }

    },
  },
  created () {
    this.selectionCountries = this.$store.state.selectionCountries;
  },
}
</script>

<style scoped lang="scss">
.form-group{
  margin: 1.5rem !important;
  label{
    margin-bottom: .6rem;
  }
}
.footer{
  height: 6rem;
  padding: 1.5rem;
  margin-bottom: 0rem !important;
  margin-top: 4rem !important;
  border-top: solid 1px #555;
  align-content: center;
  justify-content: space-between;
}
button.btn-primary{
  font-size: 13px;
  padding: 0px 15px;
  font-weight: bold
}
button{
  margin: 0px 10px !important;
}
button a{
  color: #fff !important;
  text-decoration: none;
}
.d-flex button{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
label{
  font-size: 14px;
  color: #333
}
.alert-box {
  width: 100%;
  position: absolute;
  //top: 2rem;
  margin: 0 auto;
  text-align: center;
  left: calc(0%);
  z-index: 8073;
}
</style>