<template>
  <div class="soso">
    <div>
      <b-button id="show-btn" @click="showModal" variant="secondary">
        اضافة دولة
      </b-button>
      <b-modal
          dir="rtl"
          size="xl"
          ref="add-post-modal"
          hide-header-close
          hide-footer
          title="قم بإدخال معلومات البلد الجديد">
        <form ref="form" @submit.stop.prevent="addCountry">

          <div class="d-block text-right">

            <div class="form-group">
              <label for="exampleInputEmail13">اسم الدولة</label>
              <input type="text" class="form-control" id="exampleInputEmail13"
                     v-model="country.name" aria-describedby="" placeholder="" required>
            </div>

            <div class="form-group">
              <label for="example-datepicker">اختر تاريخ الزيارة</label>
              <b-form-datepicker id="example-datepicker"
                                 :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                 v-model="country.visit_date" class="mb-2" required></b-form-datepicker>
            </div>

            <div class="form-group d-flex justify-content-between footer">
              <b-button class="m-3" type="submit" variant="primary" >إضافة</b-button>
              <b-button class="mt-3" variant="secondary"  @click="hideModal()">إغلاق</b-button>
            </div>

          </div>


        </form>


        <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
      </b-modal>
    </div>
  </div>
</template>

<script>
import repository from "../../api/repository";

export default {
  name: "addCountryModal",
  data: function () {
    return{
      country:{
        name:null,
        visit_date:null,
      },
      alertDisplay: false,
      alertType:'danger',
      alertText:''
    }
  },
  methods: {
    showModal() {
      this.$refs['add-post-modal'].show()
    },
    hideModal() {
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
      this.$refs['add-post-modal'].hide()
    },
    async addCountry() {

      const {data} = await repository.addCountry(this.country);

        if (data && data.code == 200){
          this.alertText = 'تمت الاضافة بنجاح';
          this.alertType = 'success';
          this.alertDisplay =true;
          window.location.reload(false);

        }
        else {
          this.alertText = 'حدثت مشكلة أثناء الاضافة';
          this.alertType = 'danger';
          this.alertDisplay =true;
        }


      setTimeout(this.hideModal,3000);

    }
  },
  created () {
    
  }
}
</script>

<style scoped lang="scss">
.form-group{
  margin: 1.5rem !important;
  label{
    margin-bottom: .6rem;
  }
}
.footer{
  height: 6rem;
  padding: 1.5rem;
  margin-bottom: 0rem !important;
  margin-top: 4rem !important;
  border-top: solid 1px #555;
  align-content: center;
  justify-content: space-between;
}
button.btn-primary{
  font-size: 13px;
  padding: 0px 15px;
  font-weight: bold
}
button{
  margin: 0px 10px !important;
}
button a{
  color: #fff !important;
  text-decoration: none;
}
.d-flex button{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
label{
  font-size: 14px;
  color: #333
}
.alert-box {
  width: 100%;
  position: absolute;
  //top: 2rem;
  margin: 0 auto;
  text-align: center;
  left: calc(0%);
  z-index: 8073;
}
</style>