<template>

  <div class="articles-page" >
    <introduction title="ستجد هنا جميع المقالات التى كتبتها">
      دبي هي عاصمة إحدى الإمارات السبع العربية المتحدة. تقع جنوب الخليج العربي
      في شبه الجزيرة العرب يمكن أن يكون هذا العنصر عبارة عن مشاركة مدونة مثل هذا
      المثال ، وصفحة ، ومهمة ، ومنتج ، وحتى معرض للصور التي تفتح تراكبًا. لا
      حدود لإبداعك وإنشاء صفحات الويب الخاصة
    </introduction>
    <div class="my-container">
      <div class="article-wrap">
        <div class="container">
          <div class="section-holder">
            <div class="side-bar-main mt-3">
              <main-side-bar />
            </div>
            <div class="aricles" dir="rtl">
              <div class="wraper">
                <div class="container pb-2 pl-4 mx-3" dir="rtl">

                  <b-row>
                    <b-col cols="12" class="form-group">
                      <b-form-input
                          size="lg"
                          class="mt-2 mb-2 mr-sm-2 mb-sm-0"
                          placeholder="إبحث عن موضوع معين" required
                          v-model="request.text"
                      ></b-form-input>
                    </b-col>


                  </b-row>

                </div>

                <b-container fluid dir="rtl" class="pl-0" v-if="articles">
                  <div class="results"> عدد المقالات: {{ articles.length }}</div>

                  <b-row class="w-100" >
                    <b-col cols="12" lg="6" md="6" v-for="article in articles" :key="article.id" class="mb-4">
                      <article-card
                          :imageName="'https://prod.sites-seen.com/current/public/small_images/' + article.photo"
                          :title="article.title"
                          :date="new Date(article.created_at).toDateString()"
                          :content="article.body"
                          :id="article.id"
                      ></article-card>
                    </b-col>
                  </b-row>
                </b-container>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import introduction from "../components/sections/introduction.vue";
import mainSideBar from "../components/sections/main-side-bar.vue";

import ArticleCard from "../components/article-card.vue";
import repository from "@/api/repository";

// import axios from "axios";
export default {
  data: function () {
    return {
      request:{
        text:null,
        country:0,
        city:0
      },

      articles:[],
    };
  },
  components: {
    introduction,
    mainSideBar,
    ArticleCard,
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {vm.getGoals();  });
  },
  beforeRouteUpdate (to, from, next) {
    this.articles = null;
    this.getGoals();
    next();
  },

  methods: {

    async getGoals(){

      const art = await repository.getUserGoals({
        text: this.request.text == null ? '' : this.request.text,
      });
      if (art.data){

        this.articles = art.data.articles;

      } else {
        console.log(art);
      }
    },
    async goBack(){
      // await this.$router.push({name:'admin-panel'});
    },

  },
  created() {
    this.$store.state.userVisibility = true;
  },
  watch:{

    "request.text" : function () {
      var timeout = null;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(this.getGoals(), 2000);
    },
  },
  async mounted () {

  }
};
</script>

<style lang="scss" scoped>
.results{
  font-size: 1.3rem;
  margin: 1rem auto ;
  margin-top: 0 !important;
}
.articles-page {
  font-family: var(--main-font);
  .article-wrap {
    padding: 20px 0;
    .section-holder {
      display: grid;
      grid-template-columns: 1fr 3fr;
      column-gap: 40px;
      .aricles {
        select {
          width: 100%;
          height: 50px;
          outline: none;
          padding: 0 20px;
          border: 3px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
          color: #333;
          font-weight: 600;
          cursor: pointer;
          border-radius: 7px;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
          option {
            padding: 10px 0;
            margin: 5px 0;
          }
        }
        .select-holder{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          width: 50%;
          margin-left: auto;
          button.main-btn{
            border-radius: 7px;
            background-color: #333;
            color: #fff;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .articles-page .article-wrap .section-holder {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .articles-page .article-wrap .section-holder .aricles .select-holder{
    width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .my-container .container {
    max-width: 1520px !important;
    margin: 0 auto !important;
  }
}

.title{
  font-size: 2rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(165, 138, 84, 0.42) 100%);

}
.countries{
  position: absolute;
  width: inherit;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  color: rgba(205, 200, 61, 0.88) !important
}
.recommended{
  padding: 60px 0 100px 0;
  background-color: #f7f7f7;
  .heading{
    h2{
      font-family: var(--main-font);
      margin-bottom: 60px;
      font-weight: 600
    }
  }
  .holder{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    .blog .blog-img img{
      display: none !important
    }
  }
}
@media (max-width: 768px ){
  .recommended .holder{
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1400px){
  .my-container .container{
    max-width: 1520px !important;
    margin: 0 auto !important;
  }
}

.wraper{
  font-family: var(--main-font);
}

</style>
