import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./icon.js";
import { BootstrapVue, IconsPlugin  } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import ToggleButton from 'vue-js-toggle-button'



Vue.config.productionTip = false;

Vue.use(BootstrapVue, IconsPlugin);
Vue.use(ToggleButton);

window.axios = require("axios")



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");