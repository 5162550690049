<template>

  <div class="articles-page" >
    <introduction title="ستجد هنا جميع المقالات التى كتبتها">
      دبي هي عاصمة إحدى الإمارات السبع العربية المتحدة. تقع جنوب الخليج العربي
      في شبه الجزيرة العرب يمكن أن يكون هذا العنصر عبارة عن مشاركة مدونة مثل هذا
      المثال ، وصفحة ، ومهمة ، ومنتج ، وحتى معرض للصور التي تفتح تراكبًا. لا
      حدود لإبداعك وإنشاء صفحات الويب الخاصة
    </introduction>
    <div class="my-container">
      <div class="article-wrap">
        <div class="container">
          <div class="section-holder" dir="rtl">
            <div class="articles" dir="rtl">
              <div class="wraper">
                <b-container dir="rtl" class="pl-0" >

                  <b-row  v-if="pageData">

                    <b-col cols="12" class="my-3" >
                      <span  class="title">
                        {{ article.title }}
                      </span>
                    </b-col>
                    <b-col cols="12"  >
                        <img :src="'https://prod.sites-seen.com/current/public/images/' +article.photo"
                             style="max-width: 98% !important;"
                             onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "

                             class="mb-2"
                             alt="" />
                    </b-col>

                    <b-col  class="my-3 mb-5" cols="12" >
                      <b-pagination
                          v-if="parseInt(pageData.total) > 1"
                          size="sm"
                          align="right"
                          first-text="الأولى"
                          last-text="الأخيرة"
                          v-model="page"
                          :total-rows="parseInt(pageData.total)"
                          :per-page="1"
                      ></b-pagination>
                    </b-col>

                    <b-col cols="12" id="pagecontent" ref="pagecontent" class="form-group">

                      <page-content
                          v-if="pageData.data.length > 0" :data="pageData.data[0].text"

                      ></page-content>


                    </b-col>

                    <b-col class="my-4" dir="rtl" >
                      <b-pagination
                          v-if="parseInt(pageData.total) > 1"
                          size="sm"
                          align="right"
                          first-text="الأولى"
                          last-text="الأخيرة"
                          v-model="page"
                          :total-rows="parseInt(pageData.total)"
                          :per-page="1"
                      ></b-pagination>
                    </b-col>

                  </b-row>

                </b-container>


              </div>
            </div>
            <div class="side-bar-main mt-3">
              <main-side-bar />
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import introduction from "../components/sections/introduction.vue";
import mainSideBar from "../components/sections/main-side-bar.vue";

import repository from "@/api/repository";
import pageContent from "../components/page-content";


export default {
  data: function () {
    return {
      page: 1,
      id:null,
      pageData:null,
      article:null,
      imageHolder: false,
      clickedImage: "",
    };
  },
  components: {
    introduction,
    mainSideBar,
    pageContent
  },


  methods: {

    async getUserArticle(){

      this.id = parseInt(this.$route.params.id);
      this.page = this.$route.params.page ? parseInt(this.$route.params.page) : 1;

      const {data} = await repository.getUserArticle(this.page, {
        id: this.id
      });

      if (data){
        this.pageData = data.page;
        this.article = data.article[0];

      } else {
        console.log(data);
      }
    },
    async goToArticle(){
       await this.$router.push({name:'article', params: { id: String(this.id), page: String(this.page) }}).catch(error =>  {
         if (error.name != "NavigationDuplicated") {
           throw error;
         }
       });
       await this.getUserArticle();

     },
    async goBack(){
      await this.$router.push({name:'articles'});
    },
    openImage (event) {
      this.imageHolder = true
      this.clickedImage = event.target.src
      console.log(this.clickedImage)
    },
    closeImage() {
      this.imageHolder = false
    },
    addObserverIfDesiredNodeAvailable(){
      var target = document.querySelector('#page-content');

      if(!target || !this.pageData) {
        //The node we need does not exist yet.
        //Wait 500ms and try again
        window.setTimeout(this.addObserverIfDesiredNodeAvailable,1000);
        return;
      }

      // create an observer instance
      var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          console.log("mutation.type");
          console.log(mutation.type);
          document.querySelectorAll("img").forEach(function(img){
            img.onerror = function(){this.alt='خطأ بتحميل الصورة، الرجاء ابلاغ الادمن'; };
          })
        });
      });

      // configuration of the observer:
      var config = { attributes: true, childList: true, characterData: true };
      // pass in the target node, as well as the observer options
      observer.observe(target, config);
    },
    properWidth(frameWidth, screenWidth){
      if (screenWidth > frameWidth ){
        return frameWidth;
      } else {
        return Math.round(screenWidth*0.85);
      }
    },
  },
  watch:{
    page: function () {
      this.goToArticle();
    },
    pageData: function (){

      if (this.pageData != null){

        setTimeout(function(){

          var contentNode = document.getElementById("pagecontent");
          var frames = contentNode.getElementsByTagName('iframe');

          for (let frame of frames) {
            const parent = frame.parentNode;
            var newEl = document.createElement("DIV");
            newEl.style.textAlign = 'center';
            var newFrame = document.createElement("IFRAME");
            newFrame.style.border = '0';
            newFrame.setAttribute("src", frame.src);
            newFrame.setAttribute("width", frame.width > screen.width ? screen.width*0.85 : frame.width );
            newFrame.setAttribute("height", frame.height);
            newFrame.setAttribute("loading", "lazy");
            newEl.appendChild(newFrame);

            parent.replaceChild(newEl, frame);
          }

          }, 2000);

      }
    }
  },
  created() {

    this.pageData = null;
    this.getUserArticle();

  },
  mounted() {

  },


};
</script>

<style lang="scss"  >

/* New Add Styles */
.page-item{
  button{
    margin: 0 !important;
  }
}

.page-item.active .page-link{
  color: white !important;
  background-color: #5f6c78 !important;
  border-color: #5f6c78 !important;
}

.pagination-sm .page-item:last-child .page-link{
  border-radius:  0.2rem 0 0 0.2rem   !important;
}
.pagination-sm .page-item:first-child .page-link{
  border-radius: 0 0.2rem 0.2rem 0  !important;
}
.title{
  font-size: 4rem;
  display: inline !important;
}

[v-cloak] {
  display: none;
}

#pagecontent{
  text-align: left;
  white-space: break-spaces !important;
}

 .ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: auto !important;
    margin-right: auto !important;
   text-align: right;
 }

.ql-align-right{
  text-align: right !important;
}

.ql-align-center{
  text-align: center !important;
}

.articles-page {
  font-family: var(--main-font);
  .article-wrap {
    padding: 12px 0;
    .section-holder {
      display: grid;
      grid-template-columns: 3fr 1fr;
      column-gap: 10px;

    }
  }
}



@media (min-width: 1400px) {
  .my-container .container {
    max-width: 1520px !important;
    margin: 0 auto !important;
  }
}




@media (min-width: 1400px){
  .my-container .container{
    max-width: 1520px !important;
    margin: 0 auto !important;
  }
}

.wraper{
  max-width: 90%;
  font-family: var(--main-font);
}


.side-bar-main{
  max-width: fit-content;
}

.pagination-div{
  left: calc(10%) !important;
}
.page-link{
  color: #111 !important;
  padding: 0.5rem 0.35rem !important;
}

@media (max-width: 1400px) {
  .articles-page .article-wrap .section-holder {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .articles-page .article-wrap .section-holder .aricles .select-holder{
    width: 100% !important;
  }
  .title{
    font-size: 2.5rem;
    display: inline !important;
  }

}

@media (min-width: 1400px) {
  .my-container .container {
    max-width: 1520px !important;
    margin: 0 auto !important;
  }
}

@media (max-width: 1400px) {
  #pagecontent {
    max-width: 95vw;
    img{
      max-width: 92vw !important;
      width: 70vw !important;
      height: auto !important;
    }
  }

}

.countries{
  position: absolute;
  width: inherit;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  color: rgba(205, 200, 61, 0.88) !important
}
.recommended{
  padding: 60px 0 100px 0;
  background-color: #f7f7f7;
  .heading{
    h2{
      font-family: var(--main-font);
      margin-bottom: 60px;
      font-weight: 600
    }
  }
  .holder{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    .blog .blog-img img{
      display: none !important
    }
  }
}
@media (max-width: 768px ){
  .recommended .holder{
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1400px){
  .my-container .container{
    max-width: 1520px !important;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 500px) {

  #pagecontent {
    h4 {
      font-size: 1em !important;
    }
    h3 {
      font-size: 1.2em !important;
    }
    h2 {
      font-size: 1.5em !important;
    }
    h1 {
      font-size: 2em !important;
    }
  }

}

</style>
