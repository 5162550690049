<template>
  <div class="my-container">
    <div class="recommended">
      <div class="container">
        <div class="heading">
          <h2>مقالات اخرى للقراءة</h2>
        </div>
        <div  v-show="articles">

          <Carousel :articles="articles" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Carousel  from "../carousel";


export default {
  components: { 
    // blog ,
    Carousel,
  },
  name: "recommended",
  data() {
    return {
      articles:null,
    }
  },
  created() {
    this.articles = this.$store.state.randomArticles;
  },
  watch:{
    "$store.state.randomArticles": function (){
      this.articles = this.$store.state.randomArticles;
    },
  }
}
</script>

<style lang="scss" scoped>
.recommended{
  padding: 60px 0 100px 0;
  background-color: #f7f7f7;
  .heading{
    h2{
      font-family: var(--main-font);
      margin-bottom: 60px;
      font-weight: 600
    }
  }
  .holder{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    .blog .blog-img img{
      display: none !important
    }
  }
}
@media (max-width: 768px ){
  .recommended .holder{
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1400px){
  .my-container .container{
    min-width: 98%;
    margin: 0 auto !important;
  }
}
</style>