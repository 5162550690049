import Vue from "vue";
import VueRouter from "vue-router";
import middleware from "../router/middleware";
// import store from '../store/index.js';

import Home from "../views/Home.vue";
import post from "../components/article.vue";
import articles from "../views/articles.vue";
import article from "../views/article.vue";
import goals from "../views/goals.vue";
import controlPanel from "../views/control-panel.vue";
import editArticle from "../views/edit-article.vue";
import editPage from "../views/edit-page.vue";
import complains from "../components/dashboard/complains-table.vue";


import SignIn from "../views/SignIn"
// import store from "@/store";

// const DEFAULT_TITLE = 'Hasan Elhajery';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
      footer:1
    }
  },
  {
    path: "/articles",
    name: "articles",
    component: articles,
    // beforeEnter: middleware.guest,
    meta: {
      title: "Articles",
      footer:1
    },
  },
  {
    path: "/goals",
    name: "goals",
    component: goals,
    // beforeEnter: middleware.guest,
    meta: {
      title: "Goals & Mission",
      footer:1
    },
  },
  {
    path: "/articles/:id/page/:page",
    name: "article",
    component: article,
    meta: {
      title: "Article Details",
      footer:1
    },
  },
  {
    path: "/post",
    name: "post",
    component: post,
    // beforeEnter: middleware.guest,
    meta: {
      title: "articles",
      footer:1
    },
  },
  {
    path: "/admin-55866258",
    name: "SignIn",
    component: SignIn,
    beforeEnter: middleware.guest,
    meta: {
      title: "Sign In",
      footer:0
    }
  },
  {
    path: "/admin-panel",
    name: "admin-panel",
    component: controlPanel,
    beforeEnter: middleware.user,
    meta: {
      title: "Control Panel",
      footer:0
    },
  },
  {
    path: "/admin-panel/edit-article/:id",
    name: "edit-article",
    component: editArticle,
    beforeEnter: middleware.user,
    meta: {
      title: "Edit Article",
      footer:0
    },
  },
  {
    path: "/admin-panel/edit-article/page/:id",
    name: "edit-page",
    component: editPage,
    beforeEnter: middleware.user,
    meta: {
      title: "Edit Page",
      footer:0
    },
  },
  {
    path: "/admin-panel/complains",
    name: "complains",
    component: complains,
    beforeEnter: middleware.user,
    meta: {
      title: "Complains",
      footer:0
    },
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active" , // active class for *exact* links.
  scrollBehavior () {
    return { x: 0, y: 0 };
  }

});


// router.afterEach((to, from) => {
//   // if (to.name == 'SignIn'){
//   //   store.state.footerVisibility = false;
//   // } else {
//   //   store.state.footerVisibility = true;
//   // }
//
//   // Vue.nextTick(() => {
//   //   document.title = `Hassan | ${ from.meta.title }` || DEFAULT_TITLE;
//   // });
// });

export default router;
