<template>
  <div class="my-container main-c">
    <div class="container">
    <div class="my-container">
      <div class="home-blog-posts">
        <div class="heading" >
          <h2>المقالات</h2>
          <p>مقالات قمت بكاتبتها عن الدول التى زرتها</p>
        </div>
        <blogs-intro />

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import blogsIntro from "../sections/blogs-intro.vue";
// import post from "../blog.vue";

export default {
  name: "home-blog-posts",
  components: {
    // post,
    blogsIntro
  },
  computed: {

  },
};
</script>

<style lang="scss" scoped>
.main-c{
  //height: 68rem !important;
  //min-height: content-box !important;
  background-color: #f7f7f7;
}
.home-blog-posts {
  padding: 80px 0;
  padding-bottom: 0 !important;
  font-family: var(--main-font);
  .heading {
    h2 {
      font-size: 36px;
      color: var(--main-color);
      position: relative;
      font-weight: 600;
      margin-bottom: 40px;
      &::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 3px;
        background-color: var(--third-color);
        bottom: -20px;
        left: calc(50% - 20px);
      }
    }
    p {
      font-size: 18px;
      color: rgb(54, 54, 54);
      margin-bottom: 50px;
    }
  }
  .holder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 50px;
  }
  .section-holder {
    padding: 0 0px;
  }
}
@media (max-width: 990px) {
  .home-blog-posts .holder {
    grid-template-columns: 1fr;
  }
  .section-holder{
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 500px){
  .home-blog-posts .section-holder{
    padding: 0 15px;
    margin-top: 35px;
  }
  .home-blog-posts .section-holder .side-bar{
    grid-template-columns: 1fr;
    padding: 0;
    .side-img{
      order: 2;
      height: auto !important;
    }
    .home-blog-posts .section-holder .side-bar .side-posts{
      order: 1 !important;
    }
  }
  .home-blog-posts .section-holder .side-bar .side-posts{
      order: 2;
      display: grid !important;
      grid-template-columns: 1fr 1fr;
  }
  .home-blog-posts .section-holder .side-bar .side-posts .side-post .post-text h3{
    font-size: 10px;
  }
  .home-blog-posts{
    padding: 30px 0 !important;
  }
}

@media (min-width: 1400px){
  .container {
    min-width: 98% !important;
  }
}
@media (max-width: 2000px){
  .c-main {
    min-height: fit-content !important;
    height: fit-content !important;
  }
}
@media screen and (max-width: 774px) {
  .main-c {
    padding-bottom: 2rem !important;
  }
}
</style>
