<template>
  <div class="home">
    <intro title="افضل واجهة مائية">
      دبي هي عاصمة إحدى الإمارات السبع العربية المتحدة. تقع جنوب الخليج العربي
      في شبه الجزيرة العرب يمكن أن يكون هذا العنصر عبارة عن مشاركة مدونة مثل هذا
      المثال ، وصفحة ، ومهمة ، ومنتج ، وحتى معرض للصور التي تفتح تراكبًا. لا
      حدود لإبداعك وإنشاء صفحات الويب الخاصة
    </intro>
    <firstPost />
    <gallery />
    <myvideo @videoTurnOn="updateVideoOpen($event)" />
    <video-holder
      vidSrc="https://www.youtube.com/embed/Qmi-Xwq-MEc"
      startAt="158"
      v-if="videoOn"
    />
    <home-blog-posts />

    <main-posts/>

    <recommended   />

  </div>

</template>

<script>
import intro from "../components/sections/introduction.vue";
import firstPost from "../components/sections/first-post.vue";
import gallery from "../components/sections/gallery.vue";
import myvideo from "../components/sections/video.vue";
import videoHolder from "../components/video-holder.vue";
import homeBlogPosts from "../components/sections/home-blog-posts.vue";
import recommended from "../components/sections/recommended.vue";
import mainPosts from "../components/sections/main-posts.vue";

export default {
  name: "Home",
  data: function () {
    return {
      videoOn: false,
    };
  },
  methods: {
    updateVideoOpen(state) {
      this.videoOn = state;
    },

  },
  components: {
    intro,
    firstPost,
    gallery,
    myvideo,
    videoHolder,
    homeBlogPosts,
    recommended,
    mainPosts,
  },
  mounted () {

    const sections = new Array();
    sections.push(document.querySelector(".first-post .tempreture"))
    sections.push(document.querySelector(".first-post .post"))
    sections.push(document.querySelector(".gallery"))
    sections.push(document.querySelector(".video"))
    sections.push(document.querySelector(".home-blog-posts"))
    sections.push(document.querySelector(".home-blog-posts"))
    document.querySelectorAll(".gallery .box").forEach(element => {
      sections.push(element)
    })
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0){
          // console.log(entry.intersectionRatio)
          entry.target.style.animationPlayState = "running"
          entry.target.style.opacity = "1"
        }

      })
    }, {threshold: 0.02});
    sections.forEach(section => {
      observer.observe(section)
    })

  },
  

};
</script>
