<template>
  <div class="my-container">
    <div class="navbar-boot">
      <b-navbar toggleable="lg" type="light" variant="info">
        <b-navbar-brand href="#"><img @click.prevent="goHome" src="../assets/sites-seen-logo-5.png" alt=""></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="ml-auto" dir="rtl">
          <b-navbar-nav>
            <b-nav-item href="#" @click="logOut()" v-if="isAuth"><fa-icon :icon="['fas', 'sign-out-alt']"/></b-nav-item>
            <b-nav-item href="#"><router-link to="/">الرئيسية</router-link></b-nav-item>
            <b-nav-item href="#"><router-link :to="{name: 'articles'}">مقالاتي</router-link></b-nav-item>
            <b-nav-item href="#"><router-link :to="{name: 'goals'}">رسائل و أهداف</router-link></b-nav-item>
            <b-nav-item href="#" v-if="authenticated"><router-link :to="{name: 'admin-panel'}">لوحة التحكم</router-link></b-nav-item>
            <b-nav-item href="#" v-if="authenticated" class="text-info"
            @click.prevent="logOut"
            >تسجيل الخروج</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
  </div>
</template>

<script>
import  { mapGetters } from "vuex";

export default {
  name: "navbar",
  components: {
  },
  data () {
    return{
      isAuth: false
    }
  },
  methods: {
    async logOut () {
      try {
        await this.$store.dispatch('logout');
        await this.$router.push({name: 'Home'});
      } catch (error){
        console.log("Logout Error:");
        console.log(error);
      }
    },
    async goHome(){
      await this.$router.push({name:'Home'}).catch(()=>{});
    },
  },
  computed:{
    ...mapGetters( {
      authenticated:'authenticated'
    }),
  },
  created () {
    // if (auth.currentUser != null) this.isAuth = true;
    // else this.isAuth = false
  }
}
</script>

<style lang="scss" scoped>
.navbar-boot nav{
  padding: 0px 30px;
  font-family: var(--main-font);
  a{
    text-decoration: none;
    color: var(--main-color);
    font-size: 18px;
    transition: color 0.4s ease-in-out;
    margin-left: 18px;
    font-weight: 600;
    &:hover{
      color: var(--third-color);
    }
  }
  a.navbar-brand{
    margin: .6rem;
    width: 95px;
    padding: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.navbar-boot nav.bg-info{
  background-color: #fff !important;
}

#nav-collapse{
  justify-content: flex-start;
  ul li a a.router-link-active{
    color: var(--third-color)
  }
}



@media(max-width: 990px) {
  #nav-collapse ul li{
    margin-bottom: 30px;
  }
}
</style>