<template>
  <div id="app">
    <navbar v-if="navbarVisibile"/>

    <router-view />

    <app-footer v-if="$route.meta.footer === 1"/>
    <!-- <loading :load="loaded"/> -->
    <go-up-button v-if="!isPhone" v-show="navbarVisibile"/>
  </div>
</template>

<script>
import navbar from "./components/navbar.vue";
import appFooter from "./components/app-footer.vue";
// import loading from "./components/loading.vue";
import goUpButton from "./components/go-up-button.vue";
import  { mapGetters } from "vuex";
import repository from "@/api/repository";

export default {
  components: {
    navbar,
    appFooter,
    goUpButton,
  },
  data: function () {
    return {
      loaded: true,
      isPhone: true,
      visible: true,
      userVisibility: this.$store.state.userVisibility,
      recommendedVisible: true,
      navbarVisibile: true
    };
  },
  computed:{
    ...mapGetters( {
      authenticated:'authenticated'
    }),
  },
  methods: {

    async logout(){
      try {
        await this.$store.dispatch('logout');
        await this.$router.push({name: 'Home'});
      } catch (error){
        console.log("Logout Error:");
        console.log(error);
      }
    },
    async getRandomArticles(){

      const {data} = await repository.getRandomArticles();

      if (data){
        this.$store.state.randomArticles = data;
      } else {
        console.log(data);
      }
    },

  },

  async mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        this.loaded = false
      }
    }

    // document.addEventListener("DOMContentLoaded", function() {
    //   document.querySelectorAll('img').forEach(function(img){
    //     img.onerror = function(){this.src='./assets/img-not-found.png'; this.alt='خطأ بتحميل الصورة، الرجاء ابلاغ الادمن'; this.title="error loading" };
    //   })
    // });

  },

  updated() {
    document.title = 'Sites Seen | ' + this.$route.meta.title;
  },

  created () {
    document.title = 'Sites Seen | ' + this.$route.meta.title;

    this.getRandomArticles();
    const screen = window.screen.availWidth;
    if (screen <= 700) this.isPhone = true;
    else this.isPhone = false;

  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');
@import url('https://fonts.googleapis.com/css?family=Amiri&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rakkas&display=swap');
@import url('https://fonts.googleapis.com/css?family=Aref+Ruqaa&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lemonada&display=swap');
@import url('https://fonts.googleapis.com/css?family=El+Messiri&display=swap');

@import url('sass/global.css');



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  //padding: 1rem;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: .5rem;
  padding: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 100px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
