<template>
  <b-row align-h="around" dir="rtl">
    <div  class="carousel-control next">
      <b-button @click="next">
        <fa-icon :icon="['fas', 'chevron-right']" />
      </b-button>

    </div>
    <div class="carousel-container">
      <div class="carousel">
        <div class="inner" ref="inner" :style="innerStyles">
          <div class="card shadow-sm" v-for="article in articles" :key="article.id">

            <blog
                :title="article.title"
                :id="article.id"
                :imageLink="article.photo"
                :country="article.country_id == 0 ? 'مقالة عامة' : article.country.name"
                :content="article.body"
                :date="article.country_id == 0 ? article.created_at: article.country.visit_date"
            ></blog>

          </div>
        </div>
      </div>
    </div>
    <div class="carousel-control prev">
      <b-button @click="prev">
        <fa-icon :icon="['fas', 'chevron-left']" />
      </b-button>
    </div>
  </b-row>
</template>

<script>

import blog from './article-small.vue';

export default {
  data () {
    return {
      innerStyles: {},
      step: 0,
      transitioning: false,
      rightIndex: 0,
      leftIndex: 2,
    }
  },
  components: {
    blog ,
    // Carousel,
  },
  props:['articles'],
  mounted () {
    this.setStep()
    this.innerStyles = {
      transition: 'none',
      transform: `translateX(22px)`
    }
    },
  updated() {
    this.setStep()
  },
  methods: {
    setStep () {
      const innerWidth = this.$refs.inner.scrollWidth
      const totalCards = this.articles.length
      this.step = (innerWidth / totalCards)
    },
    next () {
      // console.log(this.step)
      if (this.transitioning) return;
      this.transitioning = true;
      this.moveLeft()
      this.afterTransition(() => {
        const article = this.articles.shift()
        this.articles.push(article)
        this.innerStyles = {
          transition: 'none',
          transform: `translateX(22px)`
        }
        this.transitioning = false;
      })
    },
    moveLeft () {
      this.innerStyles = {
        transform: `translateX(${(this.step)}px) `
      }
    },
    prev () {
      if (this.transitioning) return;
      this.transitioning = true;
      this.moveRight()
      this.afterTransition(() => {
        const article = this.articles.pop()
        this.articles.unshift(article)
        this.innerStyles = {
          transition: 'none',
          transform: `translateX(22px)`
        }
        this.transitioning = false
      })
    },
    moveRight () {
      this.innerStyles = {
        transform: `translateX(-${(this.step)}px)`
      }
    },
    afterTransition (callback) {
      const listener = () => {
        callback()
        this.$refs.inner.removeEventListener('transitionend', listener)
      }
      this.$refs.inner.addEventListener('transitionend', listener)
    },
  }
}
</script>

<style scoped>
.prev{
  position: relative;
  left: 1.5rem !important;
}
.carousel-container{
  max-width: 90%;
  display: inline-block;
  overflow: hidden !important;
}
.carousel {
  width: 100% !important;
  overflow: hidden !important;
  min-height: fit-content !important;
}
.carousel-control{
  max-width: 2% !important;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 4;
}
.inner {
  transition: transform 0.1s;
  white-space: nowrap;
  padding: 0.8rem;
  /*display: -webkit-box;*/
}
.card {
  width: 33.3%;
  max-width: 34%;
  margin-right: .6rem;
  display: inline-block !important;
  height: fit-content !important;
  min-height: fit-content !important;
  /*background-color: transparent;*/
  border: none !important;
  border-radius: 1rem !important;
  background-color: #ffffff;
  padding: 1rem;
  /*max-height: 25rem;*/
  /* optional */

  /*border-radius: 4px;*/
  align-items: center;
  justify-content: center;
}
/* optional */
button {
  margin-right: 5px;
  margin-top: 10px;
}
@media (max-width: 1020px ){
  .card {
    width: 99%;
    max-width: 100%;
    margin-right: .6rem;
    display: inline-block !important;
    height: fit-content !important;
    min-height: fit-content !important;
    transform: translateX(-1rem);
    border: none !important;
    border-radius: 1rem !important;
    background-color: #f7f7f7;
    padding: 1rem;
    /*max-height: 25rem;*/
    /* optional */

    /*border-radius: 4px;*/
    align-items: center;
    justify-content: center;
  }
  .prev{
    left: 2.3rem !important;
  }

}
</style>