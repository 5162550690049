<template>
  <div class="main-side-bar">
    <div class="side">
      <temperature />
      <currency-convertor />
      <div class="side-bar">
        <div class="side-img" @click="goToArticles">
          <h5>زيارة المعالم</h5>
          <img
              onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
              src="../../assets/bg/Spain2.png" alt="" />
        </div>
        <div class="side-posts" dir="ltr">
          <div
            class="side-post"
            v-for="article in getRandomArticles"
            :key="article.id"

          >
            <div class="post-text" @click="goToArticle(article.id)">
              <h3>
                {{ article.title }}
              </h3>
            </div>
            <div class="post-img">
              <img
                  @click="goToArticle(article.id)"
                :src="'https://prod.sites-seen.com/current/public/thumbnails/' + article.photo"
                  onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
                  alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import temperature from "../../components/temperature.vue";
import currencyConvertor from "../../components/currency-convertor.vue";

export default {
  name: "main-side-bar",
  components: {
    temperature,
    currencyConvertor
  },
  computed: {
    getRandomArticles() {
      return this.$store.state.randomArticles;
    },
  },
  methods:{
    async goToArticles(){
        await this.$router.push({name:'articles'});

    },
    async goToArticle(id){
      if(id != null){
        await this.$router.push({name:'article', params: { id: id, page: 1 }});
        window.location.reload(false)
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.main-side-bar {
  .side {
    .currency-convertor {
      margin-right: 0;
    }
    .side-bar {
      margin-top: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .side-img {
        border: 3px solid #161616a2;
        position: relative;
        cursor: pointer;
        height: 40%;
        h5 {
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          z-index: 44;
          font-weight: 600;
          font-size: 22px;
        }
        &::before {
          content: "";
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 4;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .side-posts {
        margin-top: 20px;
        .side-post {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-bottom: 10px;
          justify-content: flex-end;
          .post-text {
            width: 75%;
            text-align: right;
            h3 {
              font-size: 14px;
              margin: 0 8px 0 0;
              line-height: 1.5;
              transition: color 0.4s ease-in-out;
            }
          }
          .post-img {
            width: 80px;
            height: 80px;
          }
          &:hover h3 {
            color: var(--third-color);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .side-bar {
    margin-top: 50px;
    margin-bottom: 60px;
  }
  .home-blog-posts .holder .side-bar {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 400px;
    column-gap: 20px;
    .side-img {
      order: 1;
      height: auto !important;
    }
  }
}



@media screen and (max-width: 280px) {
  .side-posts {
    margin-top: 10rem ;
  }
}

@media screen and (max-width: 325px) {
  .side-posts {
    margin-top: 14rem ;
  }
}

@media screen and (max-width: 400px) {
  .side-posts {
    margin-top: 90vw ;
  }
}

@media screen and (max-width: 500px) {
  .side-posts {
    margin-top: 110vw ;
  }
}


@media (max-width: 500px) {

  .home-blog-posts .holder .side-bar {
    grid-template-columns: 1fr;
    padding: 0;
    .side-img {
      order: 2;
      height: auto !important;
    }
    .home-blog-posts .holder .side-bar .side-posts {
      order: 1 !important;
    }
  }
  .home-blog-posts .holder .side-bar .side-posts {
    order: 2;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
  .home-blog-posts .holder .side-bar .side-posts .side-post .post-text h3 {
    font-size: 10px;
  }
  .main-side-bar .side .side-bar .side-img{
    height: 400px
  }
}
</style>
