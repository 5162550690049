<template>
  <div class="soso">
    <div>
      <b-button id="show-btn" @click="showModal" variant="primary">
        اضافة مقالة
      </b-button>
      <b-modal
          dir="rtl"
          size="xl"
          ref="add-post-modal"
          hide-footer
          hide-header-close
          title="قم بإدخال معلومات المقال الجديد">
        <form ref="form" @submit.stop.prevent="addArticle">

          <div class="d-block text-right">
            <div class="form-group">
              <label for="exampleInputEmail1">العنوان</label>
              <input type="text" class="form-control" id="exampleInputEmail1"
                     v-model="article.title" aria-describedby="" placeholder="" required>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">النص المختصر</label>
              <b-form-textarea
                  id="textarea-large"
                  size="lg"
                  no-resize
                  v-model="article.body"
                  :state="article.body.length < 400 "
                  placeholder="قم بإدخال شرح مختصر (10-400 محرف)"
              ></b-form-textarea>
<!--              :state="(article.body >= 10) && (article.body < 400)"-->

            </div>

            <div class="form-group">
              <label >اختر الدولة</label>
              <b-form-select v-model="selectedCountry"
                             @change="getCities"
                             :options="selectionCountries" size="sm" class="mt-3" ></b-form-select>

            </div>

            <div class="form-group">
              <label >اختر المدينة</label>
              <b-form-select v-model="selectedCity"
                             :options="selectionCities" size="sm" class="mt-3" ></b-form-select>

            </div>

            <div class="form-group">
              <b-row>
                <b-col cols="8">
                  <label >صورة المقالة</label>
                  <b-form-file  id="article_image" @change="handleImageAdded" ></b-form-file>
                  <b-form-text id="input-live-help">انتظر حتى يكتمل تحميل الصورة وتعرض جانبا</b-form-text>
                </b-col>
                <b-col cols="4">
                  <b-img width="150" height="150" :src="'https://prod.sites-seen.com/current/public/thumbnails/' + article.photo"></b-img>
                </b-col>
              </b-row>

            </div>


          </div>

          <div class="form-group d-flex justify-content-between footer">
            <b-button class="m-3" type="submit" variant="primary" >إضافة</b-button>
            <b-button class="mt-3" variant="secondary"  @click="hideModal()">إغلاق</b-button>
          </div>
        </form>


        <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
      </b-modal>
    </div>
  </div>
</template>

<script>
import repository from "../../api/repository";
// import article from "@/components/article";

export default {
  name: "addArticleModal",
  props:["countries"],
  data: function () {
    return{
      localCountries: this.countries,
      selectedCountry:{value:0 , text: 'غير مرتبطة بدول'},
      selectedCity:{value:0 , text: 'غير مرتبطة بمدن'},
      selectionCountries:[],
      selectionCities:[],
      cities:null,
      article:{
        title:null,
        body:'',
        country_id:null,
        city_id:null,
        photo:null
      },
      alertDisplay: false,
      alertType:'danger',
      alertText:''
    }
  },
  methods: {
    showModal() {
      this.$refs['add-post-modal'].show()
    },
    hideModal() {
      this.$refs['add-post-modal'].hide()
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
    },
    async getCities() {

      const {data} = await repository.getCities(this.selectedCountry);
      if (data && (data.length !=0 ) ){
        this.cities = data;
        this.selectionCities = [{value:0 , text: 'غير مرتبطة بمدن'}];
        this.cities.forEach((value) =>{
          this.selectionCities.push({value:value.id, text:value.name})
        })
      } else {
        this.cities = null;
      }


    },
    async handleImageAdded(event) {

      var formData = new FormData();
      formData.append('image', event.target.files[0])

      const {data} = await repository.uploadImage(formData);
      if (data){
        this.article.photo = data.image;
      } else {
        console.log(data)
      }
    },
    async addArticle() {

      this.article.country_id = this.selectedCountry;
      this.article.city_id = this.selectedCity;

      const {data} = await repository.addArticle(this.article);
      if (data){
        if (data.id){
          this.alertText = 'تمت الاضافة بنجاح';
          this.alertType = 'success';
          this.alertDisplay =true;
          window.location.reload(false);

        }
      } else {
          this.alertText = 'مشكلة أثناء الاضافة';
          this.alertType = 'danger';
          this.alertDisplay =true;
      }

      setTimeout(this.hideModal,3000);

    }
  },
  created () {
    this.selectionCountries.push({value:0 , text: 'غير مرتبطة بدول'})
    this.selectionCountries.push.apply(this.selectionCountries, this.$store.state.selectionCountries);
  },
  watch:{
    selectedCountry: {
      handler: function(val) {
        if (val == 0 ){
          this.selectionCities = [{value:0 , text: 'غير مرتبطة بمدن'}];
          this.selectedCity = 0;
        }
      },
      immediate: true
    }

  }

}
</script>

<style scoped lang="scss">
.form-group{
  margin: 1.5rem !important;
  label{
    margin-bottom: .6rem;
  }
}
.footer{
  height: 6rem;
  padding: 1.5rem;
  margin-bottom: 0rem !important;
  margin-top: 4rem !important;
  border-top: solid 1px #555;
  align-content: center;
  justify-content: space-between;
}
button.btn-primary{
  font-size: 13px;
  padding: 0px 15px;
  font-weight: bold
}
button{
  margin: 0px 10px !important;
}
button a{
  color: #fff !important;
  text-decoration: none;
}
.d-flex button{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
label{
  font-size: 14px;
  color: #333
}
.alert-box {
  width: 100%;
  position: absolute;
  //top: 2rem;
  margin: 0 auto;
  text-align: center;
  left: calc(0%);
  z-index: 8073;
}
</style>