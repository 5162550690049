import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import {
  faUserSecret,
  faSpinner,
  faHome,
  faTag,
  faMailBulk,
  faNewspaper,
  faEnvelope,
  faReply,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faClock,
  faUser,
  faMapMarkerAlt,
  faHeart,
  faPlay,
  faExchangeAlt,
  faSignOutAlt,
  faSearch,
  faSync,
  faSave,
  faChartBar,
  faSyncAlt,
  faSun,
  faMoon,
    faCloudSun,
    faCloudMoon,
    faCloud,
    faCloudMeatball,
    faCloudRain,
    faCloudSunRain,
    faCloudMoonRain,
    faPooStorm,
    faSnowflake,
    faSmog,
    faQuestionCircle,
    faWind,
    faTint,
    faTachometerAlt,

} from "@fortawesome/free-solid-svg-icons";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";

library.add(
  faUserSecret,
  faSpinner,
  faHome,
  faTag,
  faMailBulk,
  faNewspaper,
  faEnvelope,
  faReply,
  faChevronDown,
  faChevronUp,
  faClock,
  faUser,
  faMapMarkerAlt,
  faHeart,
  faPlay,
  faExchangeAlt,
  faSignOutAlt,
    faLongArrowAltRight,
    faChevronLeft,
  faChevronRight,
    faSearch,
    faSync,
    faSave,
    faChartBar,
    faSyncAlt,
    faSun,
    faMoon,
    faCloudSun,
    faCloudMoon,
    faCloud,
    faCloudMeatball,
    faCloudRain,
    faCloudSunRain,
    faCloudMoonRain,
    faPooStorm,
    faSnowflake,
    faSmog,
    faQuestionCircle,
    faWind,
    faTint,
    faTachometerAlt,
);

library.add(faFacebookF, faInstagram, faYoutube, faTwitter);
Vue.component("fa-icon", FontAwesomeIcon);
