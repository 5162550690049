<template>
  <div class="soso">
    <div>
      <b-button id="show-btn" @click="showModal" variant="secondary">
        اضافة مدينة
      </b-button>
      <b-modal
          dir="rtl"
          size="xl"
          ref="add-post-modal"
          hide-footer
          hide-header-close
          title="قم بإدخال اسم المدينة الجديدة">
        <form v-if="selectionCountries" ref="form" @submit.stop.prevent="addCity">

          <div class="d-block text-right">
            <div class="form-group">
              <label for="countryselect">اختر الدولة</label>
              <b-form-select v-model="selected" :options="selectionCountries" size="sm" class="mt-3" id="countryselect"></b-form-select>

            </div>


            <div class="form-group">
              <label for="exampleInputEmail12">اسم المدينة</label>
              <input type="text" class="form-control" id="exampleInputEmail12"
                     v-model="city.name" aria-describedby="" placeholder="" required>
            </div>
          </div>

          <div class="form-group d-flex justify-content-between footer">
            <b-button class="m-3" type="submit" variant="primary" >إضافة</b-button>
            <b-button class="mt-3" variant="secondary"  @click="hideModal()">إغلاق</b-button>
          </div>
        </form>


        <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
      </b-modal>
    </div>
  </div>
</template>

<script>
import repository from "../../api/repository";

export default {
  name: "addCityModal",
  props:["countries"],
  data: function () {
    return{
      city:{
        name:null,
        country_id:null,
      },
      selected:null,
      selectionCountries:[],
      alertDisplay: false,
      alertType:'danger',
      alertText:''
    }
  },
  methods: {
    showModal() {
      this.$refs['add-post-modal'].show()
    },
    hideModal() {
      this.$refs['add-post-modal'].hide()
      this.alertText = '';
      this.alertType = '';
      this.alertDisplay =false;
    },
    async addCity() {

      const {data} = await repository.addCity({
        'country_id':this.selected,
        'name':this.city.name
      });
      if (data && (data.id) ){
          this.alertText = 'تمت الاضافة بنجاح';
          this.alertType = 'success';
          this.alertDisplay =true;
        window.location.reload(false);

      } else {
          this.alertText = 'مشكلة أثناء الاضافة';
          this.alertType = 'danger';
          this.alertDisplay =true;
      }

      setTimeout(this.hideModal,3000);

    }
  },
  created () {
    this.selectionCountries = this.$store.state.selectionCountries;
  },
  watch:{

  }
}
</script>

<style scoped lang="scss">
.form-group{
  margin: 1.5rem !important;
  label{
    margin-bottom: .6rem;
  }
}
.footer{
  height: 6rem;
  padding: 1.5rem;
  margin-bottom: 0rem !important;
  margin-top: 4rem !important;
  border-top: solid 1px #555;
  align-content: center;
  justify-content: space-between;
}
button.btn-primary{
  font-size: 13px;
  padding: 0px 15px;
  font-weight: bold
}
button{
  margin: 0px 10px !important;
}
button a{
  color: #fff !important;
  text-decoration: none;
}
.d-flex button{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
label{
  font-size: 14px;
  color: #333
}
.alert-box {
  width: 100%;
  position: absolute;
  //top: 2rem;
  margin: 0 auto;
  text-align: center;
  left: calc(0%);
  z-index: 8073;
}
</style>