<template>
  <div class="panel-card">
    <div class="container">
      <div class="panel-cards">

        <control-panel-card
            :num="complains"
            type="عدد الاستفسارات و الشكاوى"
            :icn="['fas', 'envelope']"
            bgColor="background-color: rgb(240, 60, 60)"
            color="color: #fff"
            icnColor="color: #fff"
            goTo="complains"
        />

        <control-panel-card
          :num="count"
          type="عدد المقالات"
          :icn="['fas', 'newspaper']"
          bgColor="background-color: rgb(116, 90, 242)"
          color="color: #fff"
          icnColor="color: #fff"
        />
        <control-panel-card
          :num="views"
          type="عدد المشاهدات"
          :icn="['fas', 'user']"
          bgColor="background-color: rgb(250, 222, 10)"
          color="color: #fff"
          icnColor="color: #fff"
        />

      </div>
    </div>
  </div>
</template>

<script>
import controlPanelCard from "../dashboard/control-panel-card.vue";

export default {
  name: "panel-card",
  props: ["views",
    "count",
    "complains"],
  components: {
    controlPanelCard,
  },
  methods:{

  },
  data: function () {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>

.panel-card{
  font-family: var(--main-font);
  font-weight: 600;
  .panel-cards {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
}
</style>