<template>
  <div class="control-panel-table text-left">

    <div class="d-block text-right">

      <b-row align-h="start">
        <b-col  @click="goBack" cols="1" style="cursor: pointer;">
          الرجوع
          <fa-icon style="vertical-align: -0.18em;" :icon="['fas', 'long-arrow-alt-right']" />
        </b-col>
      </b-row>
    </div>

    <table v-show="complains" dir="rtl">
      <tr style="background-color: #d7d7e3">
        <th>الرقم</th>
        <th>الاسم</th>
        <th>تاريخ النشر</th>
        <th>البريد</th>
        <th>حذف</th>
        <th>الشكوى</th>
      </tr>
      <tr v-for="(complain, index) in complains" :key="index">
        <td>{{index + 1}}</td>
        <td>{{complain.name}}</td>
        <td> <fa-icon :icon="['fas', 'clock']" /> {{ new Date(complain.created_at).toLocaleString() }}</td>
        <td>{{complain.email}}</td>
        <td>
          <b-button class="action-btn" variant="danger" v-b-modal.modal-delete
                    @click="selectedToDelete = complain.id">حذف</b-button>

        </td>
        <td>
          <b-button id="show-btn" v-b-modal.show-complain-modal
                    @click="setData(complain.text,complain.name)"
                    variant="secondary">
          استعراض
         </b-button>
        </td>

      </tr>
    </table>

    <b-modal
        dir="rtl"
        size="xl"
        ref="show-complain-modal"
        hide-header-close
        hide-footer
        title="الشكوى الواردة">

      <div class="form-group text-right" style="margin-top: 2rem;">
        <b-card :header="'مقدم الملاحظة: ' + selectedName">
          {{ selectedComplain }}
        </b-card>
        <div class="form-group d-flex justify-content-between ">
          <b-button class="mt-3" variant="secondary"  @click="hideModal()">إغلاق</b-button>
        </div>
      </div>

    </b-modal>
    <!--  Delete Modal  -->
    <b-modal
        dir="rtl"
        size="lg"
        id="modal-delete"
        hide-footer
        hide-header-close
        title="هل تريد فعلا حذف هذه الشكوى؟">

      <div class="form-group d-flex justify-content-between footer">
        <b-button class="m-3" type="click" ref="show-delete-modal"
                  v-show="this.selectedToDelete"
                  @click.prevent="deleteComplain" variant="danger" >حذف</b-button>
        <b-button class="mt-3" variant="secondary"  @click="$bvModal.hide('modal-delete')">إغلاق</b-button>
      </div>

      <b-alert show :variant="alertType" v-if="alertDisplay" class="alert-box">{{ alertText }}</b-alert>
    </b-modal>
  </div>
</template>

<script>

import repository from "@/api/repository";

export default {
  name: 'complains-table',
  data: function () {
    return {
      complains:[],
      selectedComplain:'',
      selectedName:'',
      selectedToDelete:null,
      alertText:'',
      alertType:'',
      alertDisplay:null,
    }
  },
   methods :{
    async getComplains() {

       const {data} = await repository.getComplains();
       if (data && (data.length != 0) ){
         this.complains = data;
       } else {
         console.log(data);
       }

     },
     async deleteComplain() {

       const {data} = await repository.deleteComplain(this.selectedToDelete);
       if (data){
         if (data.code == 200){
           this.alertText = 'تم الحذف بنجاح';
           this.alertType = 'success';
           this.alertDisplay =true;
          window.location.reload(false)
         }
         if (data.code == -1){
           console.log(data)
           this.alertText = 'مشكلة أثناء الحذف';
           this.alertType = 'danger';
           this.alertDisplay =true;
         }
       } else {
         console.log(data)
         this.alertText = 'مشكلة أثناء الحذف';
         this.alertType = 'danger';
         this.alertDisplay =true;
       }

       setTimeout(this.hideDeleteModal,3000);


     },
     showModal() {
       this.$refs['show-complain-modal'].show()
     },
     hideModal() {
       this.$refs['show-complain-modal'].hide()
     },
     hideDeleteModal() {
       this.$refs['modal-delete'].hide()
     },
     setData(text, name){
       this.selectedComplain = text;
       this.selectedName = name;
       this.showModal()
     },
     async goBack(){
       await this.$router.push({name:'admin-panel'}).catch(()=>{});
     },
  },
  created () {
    this.getComplains()
  }
}
</script>

<style lang="scss" scoped>
.underline{
  text-decoration: none; color: white;
}
.control-panel-table{
  max-width: 1520px;
  margin: 0 auto;
  padding: 40px 40px;
  font-family: var(--main-font);
  .header{
    background-color: #fff;
    padding: 15px;
    h3{
      font-size: 20px;
      margin: 0;
      margin-right: 10px;
      span{
        color: #00b894;
        margin-left: 5px;
        font-size: 28px;
        font-weight: 600;
      }
    }
    input{
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      outline: none;
      font-size: 14px;
      font-weight: bold
    }
  }
  table{
    margin-top: 2rem  !important;
    background-color: #fff;
    width: 100%;
    tr{
      th{
        font-size: 1.2rem;
        text-align: center !important;
        color: #757575
      }
      td{
        text-align: center !important;
        font-size: 1rem;
        font-weight: bold;
        color: #868e96;
        padding-top: 15px;
        padding-bottom: 15px;
        svg{
          margin-right: 5px;
          font-size: 12px;
        }
        span, .action-btn{
          padding: 6px 10px;
          margin: 0 5px;
          color: #fff;
          cursor: pointer;
          font-size: .8rem;
          font-weight: bold;
          letter-spacing: 1px;
          border-radius: 4px;
        }
        span.delete{
          background-color: #ef5350;
        }
        span.edit{
          background-color: #e9ab2e ;
        }
        span.add{
          background-color: #00b894;
        }
        span.list{
          background-color: #0984e3;
        }
      }
    }
    tr:hover{
      background-color: rgba(0, 0, 0, .075)
    }
  }
}
</style>