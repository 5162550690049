<template>
  <div class="first-post">
    <div class="container">
      <div class="holder">
        <div class="tempreture">
          <temperature />

          <currency-convertor />
        </div>
        <div class="post">
          <blogPost 
            imageName=""
            title="مقال عن اليابان"
            country="تشيلى"
            date="August 4, 2021">
            خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو, إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ انه, ربع الأثنان المقيتة في, اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام, الصين وتنامت حين ٣٠, ونتج والحزب المذابح كل جوي. أسر كارثة المشتّتون بل, وبعض وبداية الصفحة غزو قد, أي بحث تعداد الجنوب.
             القاذفات عل مما. ببعض مئات وبلجيكا، قد أما, قِبل الدنمارك حتى كل, العمليات اليابانية انه أن.
          </blogPost>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blogPost from "../home-first-blog.vue";
import temperature from "../temperature.vue"
import currencyConvertor from "../currency-convertor.vue"

export default {
  name: 'first-post',
  data: function () {
    return {
      
    }
  },
  components: {
    blogPost,
    temperature,
    currencyConvertor
  },
  computed: {
    postsForHome () {
      return this.$store.state.blogPostsForHome
    }
  },
  mounted () {
    // console.log(this.postsForHome)
  }
}
</script>

<style lang="scss" scoped>
.first-post{
  padding: 3rem 0;
  .holder{
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 70px;
    .post{
      width: calc(100%)
    }
  }
}

@media(max-width: 990px){
  .first-post{padding: 70px 0}
  .first-post .holder{
    grid-template-columns: 1fr;
  }
  .blog .blog-info p{
    color: #444444;
    font-size: 15px;
  }
}

@media (min-width: 1400px){
  .container{
    max-width: 1520px;
  }
}
</style>