<template>
  <div class="loading">
    <img src="../assets/loading.gif" alt="">
  </div>
</template>

<script>
export default {
  name: "loading",
}
</script>

<style>
.loading{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 999;
}

.loading img{
  width: 80%;
  height: 100%
}
</style>