<template>
  <div class="blog" dir="rtl">
    <div class="blog-img"  @click.prevent="goToArticle">
      <img :src="imageName"
           onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
           alt="" style="max-height: 20rem !important; min-height: 20rem !important;">
    </div>
    <div class="blog-info">
      <h4 @click.prevent="goToArticle"> {{ title }}</h4>
      <div class="blog-status d-flex">
        <fa-icon :icon="['fas', 'map-marker-alt']" />
        <span> {{ country }}</span> <small>|</small>
        <fa-icon :icon="['fas', 'clock']" />
        <span>{{ date }}</span>
      </div>
      <p class="content-paragraph">
        <span class="content">{{content}}</span>
      </p>
      <button class="main-btn" @click.prevent="goToArticle">قراءة المزيد</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleCard",
  props: {
    title: {default: "Title Is Here"},
    country: {default: "مقالة عامة"},
    date: {default: "August 4, 2021"},
    imageName: {default: "blog2"},
    content: {default: "<h1>ahmed</h1>"},
    id:null,
  },
  methods:{
    async goToArticle(){
      if(this.id != null){
        await this.$router.push({name:'article', params: { id: this.id, page: 1 }});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: block;
  width: inherit;
  max-height: inherit;
  overflow-y: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}
.content-paragraph{
  min-height: 5rem !important;
  max-height: 5rem !important;
}
.blog{
  text-align: right;
  font-family: var(--main-font);
  height: 100%;
  width: auto;
  .blog-img{
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img{
      width: 100%;
      //height: 100%;
      object-fit: cover;
      transition: all 0.4s ease-in-out;
    }
  }
  .blog-info{
    h4{
      cursor: pointer;
      margin: 25px 0;
      a{
        color: var(--main-color);
        text-decoration: none;
        font-weight: 600;
        transition: color 0.4s ease-in-out;
        font-size: 30px;
        &:hover{
          color: var(--third-color);
        }
      }
    }
    .blog-status{
      align-items: center;
      font-size: 15px;
      svg{
        color: var(--main-color)
      }
      small{
        margin-inline: 10px;
      }
      span{
        margin-right: 10px;
        font-weight: bold;
        color: var(--third-color);
      }
    }
    p{
      color: var(--main-color);
      margin: 20px 0;
      margin-bottom: 1rem;
      line-height: 1.9;
      font-size: 15px
    }
    button {
      margin-top: 10px;
      background-color: var(--main-color);
      transition: all 0.5s ease;
      transition: all 0.4s ease-in-out;
      color: #fff;
      &:hover{
        background-color: rgba(0, 0, 0, 0.7)
      }
    }
  }
  &:hover .blog-img img{
    transform: scale(1.1) rotate(-2deg);
    filter: grayscale(50%)
  }
}

@media(max-width: 990px){
  .blog .blog-info h4 a{
    font-size: 25px
  }
  .blog .blog-info p{
    color: #444444;
    font-size: 15px;
  }
}

@media(max-width: 500px) {
  .blog .blog-info p{
    text-align: justify;
    font-size: 14px;
  }
  .blog .blog-img{
    border-radius: 0px;
  }
  button.main-btn{
    max-width: 1rem;
    max-height: 1rem;
  }
  .blog .blog-info button a{
    font-size: 16px;
  }
}

button.main-btn{
  margin-top: 0 !important;
  max-width: 8rem !important;
  max-height: 2.5rem !important;
}

</style>