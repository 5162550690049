<template>
  <div class="temperature-container" >
    <main class="fade-in temperature" dir="rtl">
      <b-row class="main-row" :style="{backgroundImage: 'url(https://files.elfsight.com/storage/_assets/weather/bg-images/'+ icon +'.jpg)'}">
        <b-col cols="7" style="padding: 0;">
          <b-col cols="12" lg="12" style="text-align: right">
            <h2 class="country_city_name">
              <fa-icon :icon="['fas', 'map-marker-alt']" large style="margin-left: .2rem;" />
              <span id="country_city_name">{{ this.cityName }}</span>
            </h2>
          </b-col>
          <img :src="'https://test.sites-seen.com/weather/'+ icon +'.svg'"  alt="rain" class="main-temp-icon" />
          <span id="current_weather_condition">{{ this.description }}</span>
        </b-col>
        <b-col cols="5" style="padding: 0;">
          <b-row>

            <b-col cols="12" lg="12"  class="current_temp" style="padding: 0;">
              <span id="temp">{{ this.temperature }}</span>
              <sup class="unit">&#176;</sup>
            </b-col>
            <b-col cols="12 align-text-left secondary-weather-info">
              <span id="wind">{{ this.wind }}</span>
              <span class="">mph</span>
              <fa-icon id="wind_icon" :icon="['fas', 'wind']" large />
            </b-col>
            <b-col cols="12 align-text-left secondary-weather-info">
              <span id="pressure">{{  this.pressure }}</span>
              <span class="">mb</span>
              <fa-icon id="pressure_icon" :icon="['fas', 'tachometer-alt']" large />
            </b-col>
            <b-col cols="12 align-text-left secondary-weather-info">
              <span id="humidity">{{  this.humidity }}</span>
              <span class="">%</span>
              <fa-icon id="humidity_icon" :icon="['fas', 'tint']" large />
            </b-col>
            <b-col cols="12 align-text-left secondary-weather-info temp-unit-change">
              <b-row>
                <b-col @click="changeUnit('metric')" cols="6"><b ref="metric" class="unit_select selected_unit">&#176;C</b></b-col>
                <b-col @click="changeUnit('imperial')" cols="6"><b ref="imperial" class="unit_select">&#176;F</b></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col >
      </b-row>

      <b-row class="other-days-forecast" >
        <b-col cols="12" lg="12" v-for="(i, n) in weekly" :key="n">
          <b-row style="max-width: 100%; margin: .5rem auto; padding: 0 15px;">
            <b-col cols="6" style="text-align: start">
              <b-row>
                <b-col cols="12" style="font-size: 1.2rem">
                  {{ i.date.day }}
                </b-col>
                <b-col cols="12" class="secondary" dir="rtl" style="font-size: .8rem;">
                  {{ i.date.date }}
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="2" style="padding: 0">
              <img :src="'https://test.sites-seen.com/weather/'+ i.icon +'.svg'" class="other-days-forecast-img">
            </b-col>
            <b-col cols="2" style="padding: 0" class="secondary">
              <span>{{ i.night }}</span>&#176;
            </b-col>
            <b-col cols="2" style="padding: 0">
              <span>{{  i.day }}</span>&#176;
            </b-col>
          </b-row>
        </b-col>
      </b-row>


    </main>
  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "temperature",
  data: function () {
    return {
      cityName: "",
      temperature: "",
      tempMin: "",
      tempMax:"",
      feelsLike:"",
      humidity:"",
      wind:"",
      description:"",
      lon:null,
      lat:null,
      units:"",
      icon:"",
      pressure:"",
      weekly:[],
    }
  },
  created () {
    navigator.geolocation.getCurrentPosition(position => {
       this.lon = position.coords.longitude;
       this.lat = position.coords.latitude;
       this.units = "metric";

       this.getTodayWeather();
       this.getWeeklyWeather();
    })
  },
  methods : {
    changeUnit(unit){

      if (unit != this.units){
        this.$refs.metric.classList.toggle("selected_unit");
        this.$refs.imperial.classList.toggle("selected_unit");

        this.units = unit;

        this.getTodayWeather();
        this.getWeeklyWeather();
      }

    },
    formatTime(timestamp){
      return {
        day: (new Date(timestamp)).toLocaleDateString('ar-EG', { weekday: 'long' }),
        date: (new Date(timestamp)).toLocaleDateString('ar-EG', { month: 'long', day: 'numeric' })
      }
    },
    getTodayWeather(){
      try {

        axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${this.lat}&lon=${this.lon}&units=${this.units}&lang=ar&appid=b3f95c560b82ac54d409e4bf498e8122`)
            .then(result => result.data)
            .then(data => {

              this.feelsLike = Math.round(data.main.feels_like);
              this.pressure = data.main.pressure;
              this.humidity = data.main.humidity;
              this.temperature = Math.round(data.main.temp);
              this.tempMax = Math.round(data.main.temp_max);
              this.tempMin = Math.round(data.main.temp_min);
              this.cityName = data.name;
              this.description = data.weather[0].description;
              this.icon = data.weather[0].icon;
              this.wind = data.wind.speed;

            })
            .catch(error => {
              console.log(error)
            })

      } catch (err) {
        console.error(err);
      }

    },
    getWeeklyWeather(){

      try {

        axios.get(`https://api.openweathermap.org/data/2.5/onecall?lat=${this.lat}&lon=${this.lon}&units=${this.units}&lang=ar&exclude=hourly,minutely,current,alerts&appid=b3f95c560b82ac54d409e4bf498e8122`)
            .then(result => result.data)
            .then(data => {

              this.weekly = [];

              data.daily.map((v) => {
                this.weekly.push({
                  date: this.formatTime(v.dt*1000),
                  icon: v.weather[0].icon,
                  day: Math.round(v.temp.day),
                  night: Math.round(v.temp.night)
                })
              })
              this.weekly.shift()

            })
            .catch(error => {
              console.log(error)
            })

      } catch (err) {
        console.error(err);
      }

    },

  },

}
</script>

<style lang="scss" scoped>

.other-days-forecast{
  max-width: 105%;
  overflow-y: scroll;
  box-sizing:border-box !important;
}
::-webkit-scrollbar {
  width: .5rem;
  padding: 0;
}


*{
  margin:0;
  padding:0;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  font-family: var(--main-font);
}

.main-row{
  box-sizing: border-box !important;
  outline: none !important;
  max-width: 100% !important;
  //max-height: 210px;
  align-self: center;
  border-radius: 10px 10px 0 0;
  padding: 1.4rem 0;
  padding-left: 0.5rem;
  //background-image: url("https://files.elfsight.com/storage/_assets/weather/bg-images/02n.jpg");
  background-color: rgb(77, 52, 243);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
}
#wind_icon,
#pressure_icon,
#humidity_icon{
  min-width: 1.5rem;
}
.main-temp-icon{
  width: 80%;
  margin: -10px auto -25px auto;
}
.secondary-weather-info{
  font-size: 0.8rem;
}
.week_day_forecast{
  min-height: 60px;
  flex: 0 0 12%;
  max-width: 12%;
  span{
    display: block;
    font-size: .8rem;
  }
}

.country_city_details{
  font-size: .9rem;
}
.country_city_name{
  font-size: 1.4rem;
  font-weight: 900;
  padding-right: 1rem;
}
.current_temp{
  font-size: 4rem;
}
.other-days-forecast-img{
  max-width: 100%;margin-top: -0.5rem;
}
.additional_temp{
  padding-right: 1.3rem;
  text-align: start;
  direction: rtl;
  font-size: 1rem;
}
.primary{
  font-size: 1.8rem;
}
.secondary {
  opacity: 0.7;
}
.unit_select{
  font-size: 1.5rem;
  color: #909e9e;
  cursor: pointer;
  height: 12px !important;
  line-height: 12px;
}
.selected_unit{
  text-shadow: 0 3px 10px rgba(227, 221, 221, 0.4);
  color: #ffffff;
  pointer-events: none;
}
.temp-unit-change{
  margin-top: 1rem;
}
.align-text-left{
  text-align: end;
  padding-left: 1rem;
}
.unit{
  //font-size: 1.5rem;
}
#currentForecastSecondaryDetails{
  direction: rtl;
}
@media only screen and (max-width: 520px){
  .secondary-weather-info{
    font-size:.8rem;
  }
  .current_temp{
    padding-left: 1.5rem !important;
  }
}

.temperature-container{
  //background-color: rgba(83, 111, 153, 0.9);
  background-size:cover;
  background-blend-mode:overlay;
  font-size:16px;
  font-weight:bold;
  letter-spacing:1px;
  color:#333333;
  margin: 0 auto 1rem auto;
  border-radius: 12px;
  box-shadow: 0px 0px 25px 1px rgb(50 50 50 / 7%);
  border: 3px solid rgba(0, 0, 0, 0.07);
  width:90%;
  min-height: 470px;
  max-height:600px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
}
@media only screen and (min-width: 768px){
  .temperature-container{
    font-size:22px
  }
}

main{
  width:100%;
  max-width:700px;
  max-height:460px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  color:#333333;
  border: none;
  border-radius:10px;
  -webkit-box-shadow:1px 1px 2.5px #fff;
  box-shadow:1px 1px 2.5px #fff
}
img{
  display:block
}
hr{
  width:90%;
  margin: 1.5rem auto;
  border:1px solid #d3d3d3
}
.none{
  display:none
}
.block{
  display:block
}
.offscreen{
  position:absolute;
  left:-10000px
}
.skip-link{
  position:absolute;
  top:-999px;
  left:0;
  background:#000;
  padding:14px;
  z-index:1;
  color:#fff
}
.skip-link:focus,.skip-link:active{
  top:auto;
  left:auto
}
.button{
  min-width:38px;
  min-height:38px;
  width: 40px;
  height: 40px;
  color:#fff;
  border:2px solid #d3d3d3;
  border-radius:80px;
  outline:none;
  font-size:1.25rem;
  font-weight:900;
  background-color:transparent
}
@media only screen and (min-width: 768px){
  .button{
    font-size:1.5rem;
    min-width:36px;
    min-height:36px;
    margin: 0 4px;
  }
}
.button:hover{
  cursor:pointer
}
.button:hover,.button:focus{
  -webkit-box-shadow:4px 2px 5px #000;
  box-shadow:4px 2px 5px #000;
  border-color:#fff
}
.zero-vis{
  visibility:hidden;
  opacity:0
}
.fade-in{
  visibility:visible;
  opacity:1;
  -webkit-animation-name:fadeInOpacity;
  animation-name:fadeInOpacity;
  -webkit-animation-iteration-count:1;
  animation-iteration-count:1;
  -webkit-animation-timing-function:ease-in;
  animation-timing-function:ease-in;
  -webkit-animation-duration:1.5s;
  animation-duration:1.5s
}
@-webkit-keyframes fadeInOpacity{
  0%{
    opacity:0
  }
  100%{
    opacity:1
  }
}
@keyframes fadeInOpacity{
  0%{
    opacity:0
  }
  100%{
    opacity:1
  }
}
.searchBar{
  padding:.5rem .5rem 1.5rem
}
.searchBar .searchBar__form{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  border:2px solid #d3d3d3;
  border-radius:500px
}
.searchBar .searchBar__form .searchBar__text{
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  border:none;
  margin-right:.25rem;
  background-color:transparent;
  color:#fff;
  width:calc(100% - 52px);
  font-size:1.25rem;
  padding:.75rem 0 .75rem .75rem;
  outline:none
}
.searchBar .searchBar__form .searchBar__text::-webkit-input-placeholder{
  color:rgba(255,255,255,.6)
}
.searchBar .searchBar__form .searchBar__text::-moz-placeholder{
  color:rgba(255,255,255,.6)
}
.searchBar .searchBar__form .searchBar__text:-ms-input-placeholder{
  color:rgba(255,255,255,.6)
}
.searchBar .searchBar__form .searchBar__text::-ms-input-placeholder{
  color:rgba(255,255,255,.6)
}
.searchBar .searchBar__form .searchBar__text::placeholder{
  color:rgba(255,255,255,.6)
}
.searchBar .searchBar__form .searchBar__button{
  border:none
}
.searchBar .searchBar__form .searchBar__button i{
  font-size:1.7rem
}
.searchBar .searchBar__form .searchBar__button:hover,.searchBar .searchBar__form .searchBar__button:focus{
  border:none;
  -webkit-box-shadow:none;
  box-shadow:none;
  text-shadow:4px 2px 5px #000
}
.searchBar .searchBar__form:focus-within,.searchBar .searchBar__form:hover{
  -webkit-box-shadow:4px 2px 5px #000;
  box-shadow:4px 2px 5px #000
}
.navButtons{
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:space-evenly;
  -ms-flex-pack:space-evenly;
  justify-content:space-evenly;
  padding:.5rem .25rem
}
.currentForecast .currentForecast__location{
  font-size:1rem;
  font-weight:normal;
  text-align:center;
  margin-bottom:1rem
}
@media only screen and (min-width: 768px){
  .currentForecast .currentForecast__location{
    font-size:1.3rem
  }
}
.currentForecast .currentForecast__conditions{
  width:100%;
  font-size:1.1rem;
  margin:auto auto 2rem;
  display:grid
}
@media only screen and (min-width: 375px){
  .currentForecast .currentForecast__conditions{
    font-size:1.3rem
  }
}
@media only screen and (min-width: 400px){
  .currentForecast .currentForecast__conditions{
    font-size:1.7rem
  }
}
.currentForecast .currentForecast__conditions .temp{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  grid-row:1/span 3;
  grid-column:1/span 2;
  font-size:6rem;
  letter-spacing:0;
  text-shadow:1px 0 5px #000;
  position:relative
}
@media only screen and (min-width: 360px){
  .currentForecast .currentForecast__conditions .temp{
    font-size:8rem
  }
}
@media only screen and (min-width: 768px){
  .currentForecast .currentForecast__conditions .temp{
    font-size:9rem
  }
}
.currentForecast .currentForecast__conditions .temp .unit{
  font-size:1.65rem;
  color:rgba(255,255,255,.6);
  text-shadow:none;
  position:absolute;
  top:55%;
  left:85%
}
@media only screen and (min-width: 768px){
  .currentForecast .currentForecast__conditions .temp .unit{
    left:75%
  }
}
.currentForecast .currentForecast__conditions .icon{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  grid-row:1/span 1;
  grid-column:3/span 2
}
.currentForecast .currentForecast__conditions .icon i{
  font-size:3rem;
  text-shadow:1px 0 5px #000
}
@media only screen and (min-width: 768px){
  .currentForecast .currentForecast__conditions .icon i{
    font-size:4rem
  }
}
.currentForecast .currentForecast__conditions .maxtemp{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  grid-row:2/span 1;
  grid-column:3/span 2
}
.currentForecast .currentForecast__conditions .mintemp{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  align-self:start;
  grid-row:3/span 1;
  grid-column:3/span 2;
  color:rgba(255,255,255,.6)
}
.currentForecast .currentForecast__conditions .desc{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  grid-row:4/span 1;
  grid-column:1/span 4;
  padding-top:.5rem
}
@media only screen and (max-width: 1024px)and (min-height: 650px){
  .currentForecast .currentForecast__conditions .desc{
    margin-top:1.5rem
  }
}
@media only screen and (min-height: 1080px){
  .currentForecast .currentForecast__conditions .desc{
    margin-top:1.5rem
  }
}
.currentForecast .currentForecast__conditions .feels{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  grid-row:5/span 1;
  grid-column:1/span 4;
  padding-top:.5rem;
  font-weight:normal
}
.currentForecast .currentForecast__conditions .humidity{
  display:none;
  grid-row:6/span 1;
  grid-column:1/span 4;
  padding-top:.5rem;
  font-weight:normal;
  color:rgba(255,255,255,.6)
}
@media only screen and (max-width: 1024px)and (min-height: 700px){
  .currentForecast .currentForecast__conditions .humidity{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
  }
}
@media only screen and (min-height: 1080px){
  .currentForecast .currentForecast__conditions .humidity{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
  }
}
.currentForecast .currentForecast__conditions .wind{
  display:none;
  grid-row:7/span 1;
  grid-column:1/span 4;
  padding-top:.5rem;
  font-weight:normal;
  color:rgba(255,255,255,.6)
}
@media only screen and (max-width: 1024px)and (min-height: 700px){
  .currentForecast .currentForecast__conditions .wind{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
  }
}
@media only screen and (min-height: 1080px){
  .currentForecast .currentForecast__conditions .wind{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
  }
}
.dailyForecast .dailyForecast__title{
  font-size:1.1rem;
  text-align:center;
  margin-top:1rem;
  border: 1px solid #000;
  padding-top: 1rem;
}
@media only screen and (min-width: 768px){
  .dailyForecast .dailyForecast__title{
    font-size:1.3rem
  }
}
.dailyForecast .dailyForecast__contents{
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:space-evenly;
  -ms-flex-pack:space-evenly;
  justify-content:space-evenly;
  color:#fff;
  padding:.5rem 0
}
.dailyForecast .dailyForecast__contents .forecastDay{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-pack:space-evenly;
  -ms-flex-pack:space-evenly;
  justify-content:space-evenly;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  font-size:.9rem
}
@media only screen and (min-width: 375px){
  .dailyForecast .dailyForecast__contents .forecastDay{
    font-size:1rem
  }
}
.rain{
  background-color:rgba(0,0,255,.5);
  background-image:url("../assets/rain.jpg")
}
.snow{
  background-color:rgba(0,0,255,.5);
  background-image:url("../assets/snow.jpg")
}
.night{
  background-color:rgba(204,0,102,.5);
  background-image:url("../assets/night.jpg")
}
.fog{
  background-color:#2f4f4f;
  background-image:url("../assets/fog.jpg")
}
.clouds{
  background-color:rgba(0,0,255,.5);
  background-image:url("../assets/clouds.jpg")
}
/*# sourceMappingURL=main.min.css.map */

</style>