<template>
  <div class="blogs-intro">
    <div class="container">
      <div class="holder">
        <div class="devid-smith">
          <div class="container">
            <div class="row position-relative">
              <div class="devid-img col-lg-5 col-md-4 col-sm-12">
                <img src="../../assets/bg/Spain1.png" alt="devid-smith" />
              </div>
              <div class="devid-info col-lg-7 col-md-8 col-sm-12 shadow-lg">
                <h3>مقال عن افضل رحلة وافضل بلد قمت بزيارتها</h3>
                <p>
                  خسائر اللازمة ومطالبة حدة بل. الآخر الحلفاء أن غزو, إجلاء وتنامت عدد مع. لقهر معركة لبلجيكا، بـ انه, ربع الأثنان المقيتة في, اقتصّت المحور حدة و. هذه ما طرفاً عالمية استسلام, الصين وتنامت حين ٣٠, ونتج والحزب المذابح كل جوي.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="side-bar">
          <div class="side-img" @click="goToArticles">
            <h5>زيارة المعالم</h5>
            <div class="side-img-div">
              <img src="../../assets/bg/Spain2.png"
                   onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
                   alt="" />
            </div>
          </div>
          <div class="side-posts"  >
            <div class="side-post"  v-for="article in articles" :key="article.id" if>
                <div class="post-text" @click="goToArticle(article.id)">
                  <h3>{{ article.title }} </h3>
                </div>
                <div class="post-img" @click="goToArticle(article.id)">
                  <img :src="'https://prod.sites-seen.com/current/public/thumbnails/' +article.photo"
                       onerror="this.alt='خطأ في تحميل الصورة، قم بارسال شكوى للأدمن' "
                       alt="">
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "blogs-intro",
  data: function () {
    return {
      articles: null,
    };
  },

  created() {
    this.articles = this.$store.state.randomArticles.slice(0,4);
  },
  methods:{
    async goToArticles(){
      await this.$router.push({name:'articles'});
    },
    async goToArticle(id){
      if(id != null){
        await this.$router.push({name:'article', params: { id: id, page: 1 }});
        window.location.reload(false)
      }
    },
  },
  watch:{
    "$store.state.randomArticles": function (){
      this.articles = this.$store.state.randomArticles.slice(0,4);
    },
  }

};
</script>

<style scoped lang="scss">
.container{
  min-width: 98% !important;

}
.devid-smith {
  padding: 30px 0;
  font-family: var(--main-font);
  margin-bottom: 60px;
}

.devid-smith .devid-img {
  text-align: center;
  width: 500px;
  height: 600px;
  font-size: .8rem;
}

.devid-smith .devid-img img {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  object-fit: cover;
}

.devid-smith .devid-info {
  background-color: #fff;
  padding: 75px 65px;
  position: absolute;
  top: 90px;
  right: 65px;
}

.devid-smith .devid-info h3 {
  color: #212529;
  font-size: 23px;
}

.devid-smith .devid-info p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  line-height: 1.8;
  text-align: justify;
  direction: rtl
}

.devid-smith .devid-info p:not(:last-child) {
  margin: 30px 0;
}

.holder{
  display: grid;
  grid-template-columns: 3fr 1fr;
  height: 52rem !important;
}

.side-bar{
  padding-left: 40px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .side-img{
    border: 3px solid #161616a2;
    position: relative;
    cursor: pointer;
    height: 40%;
    h5{
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      z-index: 44;
      font-weight: 600;
      font-size: 22px
    }
    .side-img-div{
      font-size: .8rem;
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::before{
      content: "";
      background-color: rgba(0, 0, 0, 0.5);

    }
  }
  .side-posts{
    margin-top: 20px;
    .side-post{
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;
      justify-content: flex-end;
      .post-text{
        width: 75%;
        text-align: right;
        h3{
          font-size: 14px;
          margin: 0 8px 0 0;
          line-height: 1.5;
          transition: color 0.4s ease-in-out;
        }
      }
      .post-img{
        width: 80px;
        height: 80px;
      }
      &:hover h3{
        color: var(--third-color)
      }
    }
  }
}

@media screen and (max-width: 774px) {
  .main-c{
    padding-bottom: 2rem !important;
  }
  .devid-smith{
   min-height: fit-content !important;
  }
  .devid-smith .devid-info{
    max-width: 90vw !important;
    left: 0 !important;
    top:0 ;
    padding: 30px 25px !important;
    position: relative;
  }

  .shadow-lg{
    box-shadow: 0 1rem 0 rgb(0 0 0 / 8%) !important;
  }

}

</style>
