<template>
  <div class="control-panel">
     <panel-cards :views="views" :count="count" :complains="complains" />
    <control-panel-table :articles="this.articles" />
  </div>
</template>

<script>
import panelCards from "../components/sections/paned-cards.vue";
import controlPanelTable from "../components/dashboard/control-panel-table.vue";
import repo from "../api/repository";

export default {
  components: {
    panelCards,
    controlPanelTable
  },
  created() {
    this.$store.state.userVisibility = false;
  },
  beforeMount() {
    this.getArticles();

  },
  methods:{
    async getArticles(){
      const {data} = await repo.getArticles();
      this.articles = data.articles;
      this.views = data.views;
      this.count = data.count;
      this.complains = data.complains;
    }
  },
  data: function () {
    return {
      articles:null,
      views:null,
      count:null,
      complains:null,
      quill: "",
    }
  },
};
</script>

<style lang="scss" scoped>
body{
  background-color: #f4f6f9 !important;
}

.control-panel {
  max-width: 1520px;
  margin: 0 auto;
  padding: 60px 0;
  background-color: #f4f6f9;
  font-family: var(--main-font);
}
</style>
